/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import { setCreateNewPropertyOverviewThunk } from "../../../../../redux-magic/store";

/* Component Imports */

import { Paper, TextField } from "@mui/material";
import { useRouter } from "next/router";

/* Styled Components */

const PaperContainer = styled(Paper)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	borderRadius: "0.75rem",
	gap: "1.5rem",
	overflow: "auto",
	marginTop: "2rem",
	// boxShadow: theme.palette.mode == "dark" ? "" : "0px 12px 24px -4px rgba(145, 158, 171, 0.12)",
	scrollbarWidth: "none", // Hide the scrollbar for firefox
	"&::-webkit-scrollbar": {
		display: "none", // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
	},
	"&-ms-overflow-style:": {
		display: "none", // Hide the scrollbar for IE
	},
	[theme.breakpoints.down("sm")]: {
		width: "100%",
	},
	background: "transparent",
	boxShadow: "none",
}));

const PropertyOverview = ({ overview_data, dispatch }: { overview_data: string; dispatch: Function }) => {
	const router = React.useRef(useRouter()).current;

	const localLang = {
		egText:
			router.locale === "ar-AE"
				? ""
				: router.locale === "kn-IN"
					? "ಉದಾ: RERA ನೋಂದಣಿಯಾಗಿದೆ, BBMP ಅನುಮೋದಿತವಾಗಿದೆ, IGBC ಪ್ರಮಾಣಿತವಾಗಿದೆ, ವಾಸ್ತು ಅನುಗ್ರಹವಿದೆ ಇತ್ಯಾದಿ"
					: "Eg: RERA Registered, BBMP Approved, IGBC Certified, Vaastu Compliance etc.",
	};

	return (
		<PaperContainer id="overview">
			<TextField
				value={overview_data}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
					dispatch(setCreateNewPropertyOverviewThunk(event.target.value));
				}}
				multiline
				rows={12}
				placeholder={localLang.egText}
				fullWidth
				autoComplete="off"
				sx={{ width: "100%" }}
			/>
		</PaperContainer>
	);
};

export default PropertyOverview;
