/* Common Imports */

import { styled, Theme, useMediaQuery } from "@mui/system";
import React from "react";

/* Redux Imports */

import StateInterface from "../../redux-magic/state-interface";

/* Component Imports */

import { useRouter } from "next/router";
import BottomNavigate from "./BottomNavigate";
import BottomNavProperty from "./BottomNavProperty";
import BottomNavService from "./BottomNavService";
import BottomNavDashboard from "./BottomNavDashboard";
import FooterContainer from "./footer-components/FooterContainer";

/* Styled LayoutFooter Component */

const LayoutFooterComponent = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	position: "relative",
	backgroundColor: theme.palette.background.paper,
	/* XXS breakpoint */
	[theme.breakpoints.only("xxs")]: {
		padding: "1rem 0.5rem 1rem 0.5rem",
	},
	/* XS breakpoint */
	[theme.breakpoints.only("xs")]: {
		padding: "1rem 1rem 1rem 1rem",
	},
	/* XSPLUS breakpoint */
	[theme.breakpoints.only("xsPlus")]: {
		padding: "1rem 1.5rem 1rem 1.5rem",
	},
	/* SM breakpoint */
	[theme.breakpoints.only("sm")]: {
		padding: "1rem 3rem 1rem 3rem",
	},
	/* SMPLUS breakpoint */
	[theme.breakpoints.only("smPlus")]: {
		padding: "1rem 3rem 1rem 3rem",
	},
	/* SM822 breakpoint */
	[theme.breakpoints.only("sm822")]: {
		padding: "1rem 3rem 1rem 3rem",
	},
	/* SM860 breakpoint */
	[theme.breakpoints.only("sm860")]: {
		padding: "1rem 3rem 1rem 3rem",
	},
	/* SM860 breakpoint */
	[theme.breakpoints.only("sm910")]: {
		padding: "1rem 3rem 1rem 3rem",
	},
	/* MD breakpoint */
	[theme.breakpoints.only("md")]: {
		padding: "1rem 4rem 1rem 4rem",
	},
	/* MD1190 breakpoint */
	[theme.breakpoints.only("md1190")]: {
		padding: "1rem 4rem 1rem 4rem",
	},
	/* MD1220 breakpoint */
	[theme.breakpoints.only("md1220")]: {
		padding: "1rem 4rem 1rem 4rem",
	},
	/* LG breakpoint */
	[theme.breakpoints.only("lg")]: {
		padding: "1rem 4rem 1rem 4rem",
	},
	/* LGPLUS breakpoint */
	[theme.breakpoints.up("lgPlus")]: {
		padding: "1rem 5rem 1rem 5rem",
	},
	/* XL breakpoint */
	[theme.breakpoints.up("xl")]: {
		padding: "1rem 23rem 1rem 23rem",
	},
	/* XXL breakpoint */
	[theme.breakpoints.up("xxl")]: {
		padding: "1rem 36rem 1rem 36rem",
	},
	/* XXXL breakpoint */
	[theme.breakpoints.up("xxxl")]: {
		padding: "1rem 72rem 1rem 72rem",
	},
}));

const LayoutFooterComponentPinned = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	position: "fixed",
	backgroundColor: theme.palette.background.paper,
	bottom: 0,
}));

/* Default Export */

const LayoutFooter = ({
	variant,
	session,
	dispatch,
	profile_context,
	new_listing_url,
	new_business_profile_url,
	propertyData,
	serviceData,
	bottom_nav_type,
	newPropertyData,
	studio,
	newServiceData,
	search_filter_state,
	searchanimationsettings,
	searchLocation,
	theme,
	dashboard_current_screen,
}: {
	variant: string | undefined;
	session?:
		| {
				user: {
					authenticationMode: boolean;
					authorized: boolean;
					date: Date;
					ipAddress: string;
					phone: string;
					validOTP: boolean;
					validPhoneNumber: string;
					rateLimitExceeded: boolean;
					sendOtpAttempts: number;
				};
				expires: Date;
		  }
		| null
		| undefined;
	dispatch: Function;
	profile_context: StateInterface["profile_context"];
	new_listing_url: StateInterface["new_listing_url"];
	new_business_profile_url: StateInterface["new_business_profile_url"];
	propertyData?: StateInterface["property"];
	serviceData?: StateInterface["single_service_page"];
	bottom_nav_type: string;
	newPropertyData: StateInterface["new_property_data"];
	studio: StateInterface["is_studio"];
	newServiceData: StateInterface["new_service_data"];
	search_filter_state: StateInterface["search_filters_state"];
	searchanimationsettings?: StateInterface["search_animation_settings"];
	searchLocation: any;
	theme: Theme;
	dashboard_current_screen?: string;
}) => {
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
	const router = useRouter();

	return (
		<React.Fragment>
			{variant === "pinned" ? (
				<LayoutFooterComponentPinned>
					<h1>The footer is visible and pinned</h1>
				</LayoutFooterComponentPinned>
			) : (
				<LayoutFooterComponent
					sx={{
						display:
							router.asPath.includes("/search") ||
							router.asPath.includes("/properties") ||
							router.asPath.includes("/services")
								? "none"
								: "flex",
					}}
				>
					<FooterContainer />
				</LayoutFooterComponent>
			)}
			{isSmallScreen && bottom_nav_type === "property" ? (
				<BottomNavProperty
					session={session}
					dispatch={dispatch}
					profile_context={profile_context}
					propertyData={propertyData}
					newPropertyData={newPropertyData as StateInterface["new_property_data"]}
					studio={studio as StateInterface["is_studio"]}
					newServiceData={newServiceData as StateInterface["new_service_data"]}
				/>
			) : isSmallScreen && bottom_nav_type === "service" ? (
				<BottomNavService
					session={session}
					dispatch={dispatch}
					profile_context={profile_context}
					serviceData={serviceData}
					newPropertyData={newPropertyData as StateInterface["new_property_data"]}
					studio={studio as StateInterface["is_studio"]}
					newServiceData={newServiceData as StateInterface["new_service_data"]}
				/>
			) : isSmallScreen && bottom_nav_type === "dashboard" ? (
				<BottomNavDashboard
					session={session}
					dispatch={dispatch}
					profile_context={profile_context}
					new_listing_url={new_listing_url}
					newPropertyData={newPropertyData as StateInterface["new_property_data"]}
					studio={studio as StateInterface["is_studio"]}
					newServiceData={newServiceData as StateInterface["new_service_data"]}
					dashboard_current_screen={dashboard_current_screen ? dashboard_current_screen : "home"}
				/>
			) : isSmallScreen ? (
				<BottomNavigate
					session={session}
					dispatch={dispatch}
					profile_context={profile_context}
					new_listing_url={new_listing_url}
					new_business_profile_url={new_business_profile_url}
					newPropertyData={newPropertyData as StateInterface["new_property_data"]}
					studio={studio as StateInterface["is_studio"]}
					newServiceData={newServiceData as StateInterface["new_service_data"]}
					search_filter_state={search_filter_state as StateInterface["search_filters_state"]}
					searchanimationsettings={searchanimationsettings as StateInterface["search_animation_settings"]}
					searchLocation={searchLocation}
				/>
			) : null}
		</React.Fragment>
	);
};

export default LayoutFooter;
