/* Common Imports */

import { styled, useMediaQuery } from "@mui/system";
import { ObjectId } from "mongodb";
import React from "react";

/* Redux Imports */

import StateInterface from "../../../redux-magic/state-interface";
import {
	createNewPropertyWithUploadToolThunk,
	setCreateNePropertyMinBalconiesThunk,
	setCreateNewePropertyPossessionDateThunk,
	setCreateNewPropertyAgeThunk,
	setCreateNewPropertyAvailabiltyThunk,
	setCreateNewPropertyCategoryTypeThunk,
	setCreateNewPropertyDefaultLocationThunk,
	setCreateNewPropertyFloorNoThunk,
	setCreateNewPropertyFurnishingThunk,
	setCreateNewPropertyLandAreaThunk,
	setCreateNewPropertyLandAreaUnitThunk,
	setCreateNewPropertyLandFacingThunk,
	setCreateNewPropertyMaxBalconiesThunk,
	setCreateNewPropertyMaxBathroomsThunk,
	setCreateNewPropertyMaxBedroomsThunk,
	setCreateNewPropertyMaxCarpetAreaThunk,
	setCreateNewPropertyMaxFloorsThunk,
	setCreateNewPropertyMaxPriceThunk,
	setCreateNewPropertyMaxSuperBuiltUpAreaThunk,
	setCreateNewPropertyMinBathroomsThunk,
	setCreateNewPropertyMinBedroomsThunk,
	setCreateNewPropertyMinCarpetAreaThunk,
	setCreateNewPropertyMinPriceThunk,
	setCreateNewPropertyMinSuperBuiltUpAreaThunk,
	setCreateNewPropertyNoOfSeatsThunk,
	setCreateNewPropertyNoOfUnitsThunk,
	setCreateNewPropertyOverviewThunk,
	setCreateNewPropertyPlotDimensionsThunk,
	setCreateNewPropertySecurityDepositThunk,
	setCreateNewPropertySubTypeThunk,
	setCreateNewPropertySuperBuiltUpAreaUnitThunk,
	setCreateNewPropertyTransactionTypeThunk,
	setCreateNewPropertyTypeThunk,
	setCreateNewPropertyUnitFacingThunk,
	setCreateNewPropertyUploadImagesThunk,
	setPostPropertyWithUploadToolSnackbarErrorThunk,
	setProfileContextThunk,
} from "../../../redux-magic/store";
import { DbUserPage } from "../../../redux-magic/sub-interfaces/user-interface";

/* Component Imports */

import PropertyCard from "../../cards/OFBCardGridMini/OFBCardGridMini/OFBCardGridMini";
import BeegruButton from "../../common-components/buttons/BeegruButton";
import PropertyDetails from "./sub-components/CreatePropertySubComponents/PropertyDetails";
import PropertyLocation from "./sub-components/CreatePropertySubComponents/PropertyLocation";
import PropertyOverview from "./sub-components/CreatePropertySubComponents/PropertyOverview";
import UploadImages from "./sub-components/CreatePropertySubComponents/UploadImages";

/* mui imports */

import {
	Alert,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	FormHelperText,
	IconButton,
	InputLabel,
	LinearProgress,
	Link,
	MenuItem,
	Select,
	Slide,
	Snackbar,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import Initials from "../../cards/Initials";

/* Icon Imports */

import { ArrowBack, Close, PersonAddAltOutlined } from "@mui/icons-material";

/* library imports */

import { TransitionProps } from "@mui/material/transitions";
import { useRouter } from "next/router";
import CapitalizeFirstLetter from "../../../lib/CapitalizeFirstLetter";
import { security_deposit } from "../../../lib/data/search-filters";
import { property_images } from "../../../redux-magic/sub-interfaces/new-property-data-interface";

/* Styled Components */

const Circle = styled(Typography)(({ theme }) => ({
	"@keyframes fadeIn": {
		"0%": {
			opacity: 0,
			// content: `${text[0]}`,
		},
		"25%": {
			opacity: 1,
		},
		"90%": {
			opacity: 1,
		},
		"100%": {
			opacity: 0,
		},
	},
	animation: "fadeIn 2s infinite",
}));
const Circle1 = styled(Typography)(({ theme }) => ({
	"@keyframes fadeIn1": {
		"0%": {
			opacity: 0,
			// content: `${text[2]}`,
		},
		"50%": {
			opacity: 1,
			// content: `${text[0]}`,
		},
		"90%": {
			opacity: 1,
		},
		"100%": {
			opacity: 0,
		},
	},
	animation: "fadeIn1 2s infinite",
}));
const Circle2 = styled(Typography)(({ theme }) => ({
	"@keyframes fadeIn2": {
		"0%": {
			opacity: 0,
			// content: `${text[0]}`,
		},

		"75%": {
			opacity: 1,
			// content: `${text[3]}`,
		},
		"90%": {
			opacity: 1,
		},
		"100%": {
			opacity: 0,
		},
	},
	animation: "fadeIn2 2s infinite",
}));

const CustomDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialog-paper": {
		[theme.breakpoints.down("sm")]: {
			borderRadius: "0rem",
			minHeight: "100%",
		},
		minHeight: "700px",
		maxHeight: "100%",
		borderRadius: "1rem",
		background: theme.palette.background.paper,
	},
}));

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	fontSize: "1.25rem",
	fontWeight: 500,
	lineHeight: "106%",
	background: theme.palette.background.paper,
	gap: "0rem",
	padding: "1rem ",
	borderRadius: "1rem 1rem 0rem 0rem",
}));

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
	padding: "1rem",
	background: theme.palette.background.paper,
	borderRadius: "0rem 0rem 1rem 1rem",
	overflow: "scroll",
}));

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
}));

const ProfilePicture = styled("img")(({ theme }) => ({
	borderRadius: "8px",
	height: "2.75rem",
	width: "2.75rem",
}));

const NameTypeContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "1rem",
	fontWeight: 400,
}));

const TypeTypo = styled(Typography)(({ theme }) => ({
	fontWeight: "400",
	fontSize: "0.875rem",
	color: theme.palette.mode == "dark" ? "#C6C6C6" : "#666666",
}));

const CustomDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));

const TextContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	gap: "0.25rem",
}));

// const StepDiv = styled("div")(({ theme }) => ({
// 	display: "flex",
// 	flexDirection: "row",
// 	justifyContent: "center",
// 	alignItems: "center",
// 	borderRadius: "2.5rem",
// 	background: theme.palette.mode == "dark" ? "#FFFFFF" : "var(--Text-New-Secondary, #666)",
// 	padding: "0rem 0.5rem",
// 	gap: "0.5rem",
// 	height: "1.5rem",
// 	width: "2.25rem",
// }));

// const CustomTypography = styled(Typography)(({ theme }) => ({
// 	fontSize: "0.75rem",
// 	fontWeight: 400,
// 	color: theme.palette.mode == "dark" ? "#212121" : "#ffffff",
// 	padding: "0rem",
// }));

const Snack = styled(Snackbar)(({ theme }) => ({}));

const ProgressContainer = styled("div")(({ theme }) => ({
	width: "100%",
	padding: "0.75rem 0rem 0rem 0rem ",
}));

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
	width: "100%",
	height: "0.25rem",
	borderRadius: "1rem",
}));

const IconTitleRow = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0rem",
	height: "auto",
}));

/* Dialog Transition */

const DialogTransition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>,
) {
	return (
		<Slide
			direction="up"
			ref={ref}
			{...props}
		/>
	);
});

/* Inrterface */

interface security_deposit {
	min: number;
	max: number;
	negotiable: boolean;
	currency: string;
}

const CreateNewOFBWithUploadTool = ({
	creator_user_id,
	business_profile_id,
	open,
	handleClose,
	dispatch,
	new_listing_url,
	profile_context,
	session,
	newPropertyData,
	pathname,
}: {
	creator_user_id: string | ObjectId;
	business_profile_id: string | ObjectId;
	open: boolean;
	handleClose: any;
	dispatch: Function;
	new_listing_url: StateInterface["new_listing_url"];
	profile_context: StateInterface["profile_context"];
	session: any;
	newPropertyData: StateInterface["new_property_data"];
	pathname?: any;
}) => {
	const theme = useTheme();

	const router = React.useRef(useRouter()).current;
	/* User business profiles */

	let businessProfiles: DbUserPage = session
		? session.user
			? session.user.dbUser
				? session.user.dbUser.pages
					? session.user.dbUser.pages[0]
					: []
				: []
			: []
		: [];

	const businessProfilesCount = session
		? session.user
			? session.user.dbUser
				? session.user.dbUser.business_profiles_count
					? session.user.dbUser.business_profiles_count
					: 0
				: 0
			: 0
		: 0;

	const smDevice: boolean = useMediaQuery(theme.breakpoints.down("sm"));

	const [activeStep, setActiveStep] = React.useState(0);

	const [propertyTitle, setPropertyTitle] = React.useState<string>("");

	const [businessProfileName, setBusinessProfileName] = React.useState<string>("");

	const [businessProfileType, setBusinessProfileType] = React.useState<string>("");

	const [createBusinessProfile, setcreateBusinessProfile] = React.useState<boolean>(false);

	const [loading, setLoading] = React.useState(false);

	const [loadNext, setLoadNext] = React.useState<boolean>(false);

	const [alertModal, setAlertModal] = React.useState<boolean>(false);

	const [openSnackbar, setOpenSnackbar] = React.useState(false);

	const [propertyNameError, setPropertyNameError] = React.useState<boolean>(false);

	const [businessProfileNameError, setBusinessProfileNameError] = React.useState<boolean>(false);

	const [businessProfileTypeError, setBusinessProfileTypeError] = React.useState<boolean>(false);

	const [transactionTypeError, setTransactionTypeError] = React.useState<boolean>(false);

	const [propertyCategoryError, setPropertyCategoryError] = React.useState<boolean>(false);

	const [propertyTypeError, setPropertyTypeError] = React.useState<boolean>(false);

	const [priceMinError, setPriceMinError] = React.useState<boolean>(false);

	const [priceMaxError, setPriceMaxError] = React.useState<boolean>(false);

	const [availabilityError, setAvailabilityError] = React.useState<boolean>(false);

	const [possessionDateError, setPossessionDateError] = React.useState<boolean>(false);

	/* Session user and name */

	const session_user = session ? (session.user ? session.user.dbUser : "") : "";

	const userName = session
		? session.user.dbUser.first_name !== ""
			? session.user.dbUser.first_name + " " + session.user.dbUser.last_name
			: session.user.dbUser.phone
		: "Beegru";

	/* User Profile picture */

	const profilePicture = session
		? session.user
			? session.user.dbUser
				? session.user.dbUser.profile_picture
					? (session.user.dbUser.profile_picture.exists as boolean)
						? session.user.dbUser.profile_picture.file_id
						: "/images/icons/Avatar.svg"
					: "/images/icons/Avatar.svg"
				: "/images/icons/Avatar.svg"
			: "/images/icons/Avatar.svg"
		: "/images/icons/Avatar.svg";

	// lets check if the 0th step is complete
	const stepZeroComplete: boolean =
		(activeStep != 0 && createBusinessProfile && (businessProfileName === "" || businessProfileType === "")) ||
		(businessProfiles === null && (businessProfileName === "" || businessProfileType === "")) ||
		propertyTitle === "" ||
		loading;

	// lets check if the 1st step is complete
	// First lets extract current location values
	const currentLatitude = newPropertyData?.search_metrics.location_coordinates[0];
	const currentLongitude = newPropertyData?.search_metrics.location_coordinates[1];

	// Next, lets extract previous location values (assuming location_data is available in scope)
	const previousLatitude = 1;
	const previousLongitude = 1;

	//Next, lets Check if location value has changed
	const didLocationChange = currentLatitude !== previousLatitude || currentLongitude !== previousLongitude;

	const stepOneComplete: boolean = activeStep === 1 && !didLocationChange;

	const [newListing, setNewListing] = React.useState<boolean>(false);

	// Finally, lets check if stepOne is complete

	// lets check if the 4th step is complete

	const stepThreeComplete: boolean = activeStep === 3 && newPropertyData?.description === "";

	// lets check if the 4th step is complete

	// const stepFourComplete: boolean = activeStep === 4 && newPropertyData?.images.length === 0;
	const stepFourComplete: boolean =
		activeStep === 4 && newPropertyData?.images.length > 0 && !newPropertyData?.image_upload_completed;

	// lets create functions to handle the stepper
	const handleNext = () => {
		if (activeStep === 0) {
			if (
				(createBusinessProfile && (businessProfileName === "" || businessProfileType === "")) ||
				(businessProfiles === null && (businessProfileName === "" || businessProfileType === "")) ||
				propertyTitle === ""
			) {
				if (propertyTitle === "") {
					setPropertyNameError(true);
				}
				if (createBusinessProfile && businessProfileName === "") {
					setBusinessProfileNameError(true);
				}
				if (createBusinessProfile && businessProfileType === "") {
					setBusinessProfileTypeError(true);
				}
			} else {
				setPropertyNameError(false);
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			}
		} else if (activeStep === 1) {
			if (didLocationChange) {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			}
		} else if (activeStep === 2) {
			if (
				newPropertyData?.property_category === "" ||
				(newPropertyData.transaction_type !== "" &&
					newPropertyData.property_category !== "" &&
					newPropertyData.property_category !== "public infrastructure" &&
					newPropertyData.property_category !== "joint development" &&
					newPropertyData.property_category !== "joint venture" &&
					newPropertyData.property_category !== "warehousing")
			) {
				if (newPropertyData?.property_category === "") {
					setPropertyCategoryError(true);
				}
				if (newPropertyData?.transaction_type === "") {
					setTransactionTypeError(true);
				}
				if (newPropertyData?.property_type?.length === 0) {
					setPropertyTypeError(true);
				} else {
					setActiveStep((prevActiveStep) => prevActiveStep + 1);
				}
			} else {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			}
		} else if (activeStep === 3) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		} else {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	/* Change profile context function */

	const onChangeProfileContext = async (id: ObjectId | string) => {
		if (id) {
			setLoading(true);
			await dispatch(
				setProfileContextThunk({
					is_business_profile: id === session_user._id ? false : true,
					user_id: session_user ? session_user._id : "",
					business_profile_id: id,
					page_type:
						id === session_user._id ? "user" : businessProfiles?.page_id === id ? businessProfiles?.page_type : "",
					// businessProfiles?.find((item: any) => item.page_id === id).page_type
					user_kyc_status: session.user.dbUser.kyc_info.kyc_status,
					user_subscription_information: session.user.dbUser.subscription_information,
					remember_choice: true,
					sessionId: session ? (session.session_id ? session.session_id : "") : "",
				}),
			);
			setLoading(false);
		}
	};

	const propertyImageObject = newPropertyData?.images.map((image: property_images) => {
		return {
			file_id: image.file_id,
			mime_type: image.mime_type,
			category: image.category,
			caption: image.caption,
			priority: image.priority,
		};
	});

	const handleClickCreateNewProperty = async () => {
		setLoading(true);
		setLoadNext(true);
		setNewListing(true);
		await dispatch(
			createNewPropertyWithUploadToolThunk({
				createNewBusinessProfile: createBusinessProfile,
				businessProfileId: createBusinessProfile ? "" : businessProfiles?.page_id,
				creatorIsBusinessProfile: createBusinessProfile ? true : profile_context?.is_business_profile,
				businessProfileName: createBusinessProfile ? businessProfileName : businessProfiles?.page_title,
				businessProfileType: createBusinessProfile ? businessProfileType.toLowerCase() : businessProfiles?.page_type,
				userId: session.user.dbUser._id,
				phoneNumber: session.user.dbUser.phone,
				countryCode: session.user.dbUser.country_code,
				propertyName: propertyTitle,
				propertyLocationName: newPropertyData?.location.title,
				propertyLocationCoordinates: newPropertyData?.search_metrics.location_coordinates,
				propertyTransactionType: newPropertyData?.transaction_type,
				propertyCategory: newPropertyData?.property_category,
				propertyType: newPropertyData?.property_type,
				propertySubtype: newPropertyData?.property_subtype,
				propertyMinPrice: newPropertyData?.price.min,
				propertyMaxPrice: newPropertyData?.price.max,
				propertySecurityDeposit: newPropertyData?.security_deposit.max,
				propertyStatus: newPropertyData?.availability[0],
				propertyPossessionDate: newPropertyData?.possession_date,
				propertyAge: newPropertyData?.property_age,
				propertyFacing: newPropertyData?.unit_facing,
				propertyFurnishing: newPropertyData?.furnished,
				propertyMinBedrooms: newPropertyData?.bhk.min,
				propertyMaxBedrooms: newPropertyData?.bhk.max,
				propertyMinBathrooms: newPropertyData?.bathroom.min,
				propertyMaxBathroom: newPropertyData?.bathroom.max,
				propertyNoOfBalconies: newPropertyData?.balconies.max,
				propertyNoOfSeats: newPropertyData?.no_of_seats.max,
				propertyNoOfUnits: newPropertyData?.no_of_units.max,
				propertyNoOfFloors: newPropertyData?.floors.max,
				propertyPlotDimensions: newPropertyData?.plot_dimensions,
				propertyLandAreaUnit: newPropertyData?.area.land_area.land_area_unit,
				propertyLandArea: newPropertyData?.area.land_area.max.acre,
				propertySuperBuiltUpAreaUnit: newPropertyData?.area.super_built_up_area.super_built_up_area_unit,
				propertyMinSuperBuiltUpArea: newPropertyData?.area.super_built_up_area.min.ft,
				propertyMaxSuperBuiltUpArea: newPropertyData?.area.super_built_up_area.max.ft,
				propertyIsCarpetAreaPercentage: true,
				propertyCarpetAreaPercentage: "0",
				propertyCarpetAreaUnit: newPropertyData?.area.carpet_area.carpet_area_unit,
				propertyMinCarpetArea: newPropertyData?.area.carpet_area.min.ft,
				propertyMaxCarpetArea: newPropertyData?.area.carpet_area.max.ft,
				propertyImages: propertyImageObject,
				propertyOverview: newPropertyData?.description,
				sessionId: session ? (session.session_id ? session.session_id : "") : "",
				locale: router.locale as string || "en-IN",
			}),
		);

		(pathname && pathname.query && pathname.query.user_post_ofb_button === "true") ||
		(pathname && pathname.query && pathname.query.agent_post_ofb_button === "true") ||
		(pathname && pathname.query && pathname.query.builder_post_ofb_button === "true") ||
		(pathname && pathname.query && pathname.query.landowner_post_ofb_button === "true") ||
		(pathname && pathname.query && pathname.query.professional_post_ofb_button === "true")
			? router.push((process.env.PRODUCTION_URL || "") + "/intro")
			: "";
	};

	// Lets check if the  profile_context is_business_profile is true

	const isBusinessProfile: boolean = profile_context ? profile_context.is_business_profile : false;

	// Lets fetch the profile_context user_id

	const profileContextUserId: string | ObjectId = profile_context ? profile_context.user_id : "";

	// Lets fetch the profile_context page_slug

	const profileContextPageSlug: string = profile_context ? profile_context.page_slug : "";

	// Lets fetch the current page

	const currentPage: String = newPropertyData ? newPropertyData.currentPage : "";

	// Lets check if the OFB creator is on his page

	const userCreatorIsOnHisPage: boolean = currentPage === profileContextUserId && !isBusinessProfile ? true : false;

	const businessCreatorOnHisPage: boolean = currentPage === profileContextPageSlug && isBusinessProfile ? true : false;

	React.useEffect(() => {
		const businessType: string = profile_context
			? profile_context.is_business_profile
				? profile_context.page_type
				: "user"
			: "user";

		if (
			new_listing_url !== undefined &&
			new_listing_url !== "" &&
			newListing &&
			!(businessCreatorOnHisPage || userCreatorIsOnHisPage)
		) {
			// handleModalClose();
			window.location.replace(`/${businessType}s/${new_listing_url}`);
		} else if (
			new_listing_url !== undefined &&
			new_listing_url !== "" &&
			newListing &&
			(businessCreatorOnHisPage || userCreatorIsOnHisPage)
		) {
			handleModalClose();
		} else if (new_listing_url !== undefined && new_listing_url !== "" && newListing) {
			setPostPropertyWithUploadToolSnackbarErrorThunk(true);
			handleModalClose();
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps*/
	}, [new_listing_url]);

	React.useEffect(() => {
		if (newPropertyData?.snack_ofb_value) {
			setOpenSnackbar(true);
		}
	}, [newPropertyData?.snack_ofb_value]);

	const handleModalClose = () => {
		setcreateBusinessProfile(false);
		setBusinessProfileName("");
		setBusinessProfileType("");
		setPropertyTitle("");
		setLoading(false);
		setLoadNext(false);
		handleClose();
		setActiveStep(0);
		dispatch(
			setCreateNewPropertyDefaultLocationThunk({
				location_coordinates: [1, 1],
				address: "",
			}),
		);
		dispatch(setCreateNewPropertyCategoryTypeThunk(""));
		dispatch(setCreateNewPropertyTransactionTypeThunk(""));
		dispatch(setCreateNewPropertyTypeThunk([""]));
		dispatch(setCreateNewPropertySubTypeThunk(""));
		dispatch(setCreateNewPropertyMinPriceThunk(0));
		dispatch(setCreateNewPropertyMaxPriceThunk(0));
		dispatch(setCreateNewPropertySecurityDepositThunk(0));
		dispatch(setCreateNewPropertyAvailabiltyThunk([""]));
		dispatch(setCreateNewePropertyPossessionDateThunk(""));
		dispatch(setCreateNewPropertyUnitFacingThunk([""]));
		dispatch(setCreateNewPropertyLandFacingThunk([""]));
		dispatch(setCreateNewPropertyAgeThunk(0));
		dispatch(setCreateNewPropertyPlotDimensionsThunk([]));
		dispatch(setCreateNewPropertyFurnishingThunk([""]));
		dispatch(setCreateNewPropertyLandAreaThunk(0));
		dispatch(setCreateNewPropertyLandAreaUnitThunk(""));
		dispatch(setCreateNewPropertyMaxBalconiesThunk(0));
		dispatch(setCreateNewPropertyMaxBathroomsThunk(0));
		dispatch(setCreateNewPropertyMaxBedroomsThunk(0));
		dispatch(setCreateNewPropertyMaxCarpetAreaThunk(0));
		dispatch(setCreateNewPropertyMaxFloorsThunk(0));
		dispatch(setCreateNewPropertyMaxSuperBuiltUpAreaThunk(0));
		dispatch(setCreateNePropertyMinBalconiesThunk(0));
		dispatch(setCreateNewPropertyMinBathroomsThunk(0));
		dispatch(setCreateNewPropertyMinBedroomsThunk(0));
		dispatch(setCreateNewPropertyMinCarpetAreaThunk(0));
		dispatch(setCreateNewPropertyMinSuperBuiltUpAreaThunk(0));
		dispatch(setCreateNewPropertyNoOfUnitsThunk(0));
		dispatch(setCreateNewPropertyNoOfSeatsThunk(0));
		dispatch(setCreateNewPropertyFloorNoThunk(0));
		dispatch(setCreateNewPropertySuperBuiltUpAreaUnitThunk(""));
		dispatch(setCreateNewPropertyOverviewThunk(""));
		dispatch(setCreateNewPropertyUploadImagesThunk([]));
		setAlertModal(false);
		setBusinessProfileNameError(false);
		setBusinessProfileTypeError(false);
		setTransactionTypeError(false);
		setPropertyNameError(false);
		setPropertyCategoryError(false);
		setPropertyTypeError(false);
	};

	const localLang = {
		postingAs: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಪೋಸ್ಟ್ ಮಾಡಿದವರು" : "Posting as",
		user: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಬಳಕೆದಾರ" : "User",
		addBusinessProfile:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ವ್ಯಾಪಾರ ಪ್ರೊಫೈಲ್ ಸೇರಿಸಿ" : "Add business profile",
		createBusinessProfile:
			router.locale === "ar-AE"
				? ""
				: router.locale === "kn-IN"
					? "ವ್ಯಾಪಾರ ಪ್ರೊಫೈಲ್ ರಚಿಸಿ. "
					: "Let's create a business profile to post your OFB. ",
		learnMore: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಮಾಹಿತಿ ಪಡೆಯಿರಿ" : "Learn more",
		businessName: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ವ್ಯಾಪಾರ ಹೆಸರು*" : "Business Name*",
		pleaseEnterName:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ದಯವಿಟ್ಟು ಹೆಸರನ್ನು ನಮೂದಿಸಿ" : "Please enter name",
		businessProfileType:
			router.locale === "ar-AE"
				? ""
				: router.locale === "kn-IN"
					? "ವ್ಯಾಪಾರ ಪ್ರೊಫೈಲ್ ಪ್ರಕಾರ*"
					: "Business Profile Type*",
		agent: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಏಜೆಂಟ್" : "Agent",
		developer: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಡೆವೆಲಪರ್" : "Developer",
		landowner: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಭೂಮಿದಾರ" : "Landowner",
		professional: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಪ್ರೊಫೆಶನಲ್" : "Professional",
		pleaseSelectAType:
			router.locale === "ar-AE"
				? ""
				: router.locale === "kn-IN"
					? "ದಯವಿಟ್ಟು ಒಂದು ಪ್ರಕಾರ ಆಯ್ಕೆಮಾಡಿ"
					: "Please select a type",
		enterNameOfYourOfb:
			router.locale === "ar-AE"
				? ""
				: router.locale === "kn-IN"
					? "ನಿಮ್ಮ OFB ಹೆಸರನ್ನು ನಮೂದಿಸಿ"
					: "Enter the name of your OFB",
		onceCreatedYouCannotChangeName:
			router.locale === "ar-AE"
				? ""
				: router.locale === "kn-IN"
					? "ಒಮ್ಮೆ ರಚಿಸಿದ ನಂತರ ನೀವು ಹೆಸರನ್ನು ಬದಲಾಯಿಸಲಾಗುವುದಿಲ್ಲ"
					: "Once created, you cannot change the name",
		ofbNameLable: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "OFB ಹೆಸರು*" : "OFB Name*",
		ofbNamePlaceholder:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "OFB ಹೆಸರನ್ನು ನಮೂದಿಸಿ" : "Enter OFB name",
		thisOfbWillBeListedUnderNewBP:
			router.locale === "ar-AE"
				? ""
				: router.locale === "kn-IN"
					? "* ಈ OFB ಹೊಸ ವ್ಯಾಪಾರ ಪ್ರೊಫೈಲ್ ಅಡಿಯಲ್ಲಿ ಪಟ್ಟಿಗೆ ಹೊಂದಿರುತ್ತದೆ"
					: "* This OFB will be listed under the new business profile",
		errorWhileCreatingOFB:
			router.locale === "ar-AE"
				? ""
				: router.locale === "kn-IN"
					? "OFB ರಚಿಸುವಾಗ ತಪ್ಪಾಯಿತು"
					: "Error while creating OFB",
		closeofbUpload:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "OFB ಅಪ್ಲೋಡ್ ಮುಚ್ಚಲಾ?" : "Close OFB Upload?",
		unsavedDataWillBeLost:
			router.locale === "ar-AE"
				? ""
				: router.locale === "kn-IN"
					? "ಅಸುರಕ್ಷಿತ ಡೇಟಾ ನಷ್ಟವಾಗುತ್ತದೆ. ನಿಮಗೆ ಖಚಿತವೇ?"
					: "All the unsaved data will be lost. Are you sure ?",
		yes: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಹೌದು" : "Yes",
		no: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಬೇಡ" : "No",
		createNewOfb: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಹೊಸ OFB ರಚಿಸಿ" : "Create New OFB",
		location: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಸ್ಥಳ" : "Location",
		details: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ವಿವರಗಳು" : "Details",
		addOverview: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಸಾರಾಂಶ ಸೇರಿಸಿ" : "Add Overview",
		uploadImage: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಚಿತ್ರ ಅಪ್ಲೋಡ್ ಮಾಡಿ" : "Upload Image",
		preview: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಮುನ್ನೋಟ" : "Preview",
		back: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಹಿಂದೆ" : "Back",
		publish: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಪ್ರಕಟಿಸಿ" : "Publish",
		next: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಮುಂದೆ" : "Next",
		creatingOFB: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "OFB ರಚಿಸುತ್ತಿದ್ದೇನೆ" : "Creating OFB",
		fieldsCannotBeEmpty:
			router.locale === "ar-AE"
				? "الحقول المعلمة بـ * هي حقول إلزامية."
				: router.locale === "kn-IN"
					? "* ಗುರುತು ಮಾಡಿದ ಕ್ಷೇತ್ರಗಳು ಕಡ್ಡಾಯವಾಗಿವೆ"
					: "Fields marked with * are mandatory",
	};

	const stepContent = (step: number) => {
		switch (step) {
			case 0:
				return (
					<CustomDiv
						sx={{
							flexDirection: "column",
							paddingTop: "2rem",
							gap: "1rem",
							direction: router.locale === "ar-AE" ? "rtl" : "ltr",
						}}
					>
						{/* Profile Select */}

						{createBusinessProfile ? null : (
							<FormControl
								variant="outlined"
								fullWidth
							>
								<InputLabel
									id="select-profile"
									sx={{ padding: "0rem" }}
								>
									{localLang.postingAs}
								</InputLabel>
								<Select
									labelId="select-profile"
									label={localLang.postingAs}
									disabled={loading}
									MenuProps={{
										disableScrollLock: true,
										MenuListProps: {
											disablePadding: true,
										},
										PaperProps: {
											sx: {
												background: theme.palette.background.default,
												maxHeight: "20rem",
												borderRadius: "16px",
											},
										},
									}}
									value={
										profile_context
											? profile_context.is_business_profile
												? profile_context.business_profile_id
													? (profile_context.business_profile_id as string)
													: ""
												: profile_context.user_id
													? (profile_context.user_id as string)
													: ""
											: ""
									}
									onChange={(e) => {
										onChangeProfileContext(e.target.value);
									}}
									size="small"
									sx={{
										// marginBottom: "0.5rem",
										minWidth: "20%",
										"& .MuiSelect-select": {
											display: "flex",
											flexDirection: "row",
											justifyContent: "flex-start",
											alignItems: "center",
											gap: "0.5rem",
										},
										"& .MuiPaper": {
											background: theme.palette.background.default,
										},
									}}
								>
									<CustomMenuItem value={session_user._id}>
										{!profilePicture.includes("/images/icons/Avatar.svg") ? (
											<ProfilePicture
												src={`/profile-picture/${profilePicture}`}
												alt={session ? session.user.dbUser.first_name.charAt(0).toUpperCase() : null}
												referrerPolicy="no-referrer"
												loading="lazy"
											/>
										) : (
											<Initials
												title={session ? session.user.dbUser.first_name + " " + session.user.dbUser.last_name : ""}
												profiledropdown={false}
												header={false}
												comment={false}
												postcard={false}
												businessprofile={false}
												selectProfile={true}
											/>
										)}
										<NameTypeContainer>
											<Heading sx={{ width: "100%" }}>{userName}</Heading>
											<TypeTypo>{localLang.user}</TypeTypo>
										</NameTypeContainer>
									</CustomMenuItem>

									{businessProfilesCount > 0 && (
										<CustomMenuItem value={businessProfiles?.page_id as string}>
											{businessProfiles?.display_picture_url?.exists ? (
												<ProfilePicture
													src={`/profile-picture/${businessProfiles?.display_picture_url?.file_id}`}
													alt={businessProfiles?.page_title.toUpperCase()}
													referrerPolicy="no-referrer"
													loading="lazy"
												/>
											) : (
												<Initials
													title={businessProfiles?.page_title}
													profiledropdown={false}
													header={false}
													comment={false}
													postcard={false}
													businessprofile={false}
													selectProfile={true}
												/>
											)}
											<NameTypeContainer>
												<Heading>
													{businessProfiles?.page_title?.length > 15
														? businessProfiles?.page_title?.slice(0, 15) + "..."
														: businessProfiles?.page_title}
												</Heading>
												<TypeTypo>{CapitalizeFirstLetter(businessProfiles?.page_type)}</TypeTypo>
											</NameTypeContainer>
										</CustomMenuItem>
									)}

									{businessProfilesCount === 0 && (
										<CustomMenuItem
											onClick={() => {
												setcreateBusinessProfile(true);
											}}
										>
											<PersonAddAltOutlined
												color="primary"
												sx={{
													margin: "0rem 0.75rem 0.25rem 0.65rem",
													fontSize: "1.5rem",
												}}
											/>
											<Typography
												variant="body1"
												sx={{
													color: theme.palette.mode == "dark" ? "#ffffff" : "#000000",
												}}
											>
												{localLang.addBusinessProfile}
											</Typography>
										</CustomMenuItem>
									)}
								</Select>
							</FormControl>
						)}

						{createBusinessProfile ? (
							<>
								<Typography variant="body2">
									{localLang.createBusinessProfile}
									<Link
										href={"/" + router.locale + "/faqs"}
										rel="noopener"
										target="_self"
										referrerPolicy="no-referrer"
										sx={{
											textDecoration: "none",
											display: "contents",
											"&:hover": {
												textDecoration: "underline",
											},
										}}
									>
										{localLang.learnMore}
									</Link>
								</Typography>

								<TextField
									label={localLang.businessName}
									required={true}
									disabled={loading}
									value={businessProfileName}
									autoComplete="off"
									variant="outlined"
									onChange={(event: any) => {
										setBusinessProfileName(event.target.value);
										setBusinessProfileNameError(false);
									}}
									sx={{ width: "100%", marginTop: "1rem" }}
									error={businessProfileNameError}
									helperText={businessProfileNameError ? localLang.pleaseEnterName : ""}
								/>
								<FormControl
									variant="outlined"
									fullWidth
									sx={{ marginTop: "1rem" }}
									error={businessProfileTypeError}
								>
									<InputLabel
										id="profile-type"
										sx={{ padding: "0rem" }}
									>
										{localLang.businessProfileType}
									</InputLabel>
									<Select
										labelId="profile-type"
										label={localLang.businessProfileType}
										required={true}
										disabled={loading}
										value={businessProfileType}
										sx={{ width: "100%" }}
										onChange={(e) => {
											setBusinessProfileType(e.target.value);
										}}
									>
										<MenuItem value="agent">{localLang.agent}</MenuItem>
										<MenuItem value="developer">{localLang.developer}</MenuItem>
										<MenuItem value="landowner">{localLang.landowner}</MenuItem>
										<MenuItem value="professional">{localLang.professional}</MenuItem>
									</Select>
									<FormHelperText>{businessProfileTypeError ? localLang.pleaseSelectAType : ""}</FormHelperText>
								</FormControl>
							</>
						) : null}
						<TextContainer sx={{ gap: "1rem" }}>
							<TextContainer sx={{ flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
								<Typography sx={{ fontSize: "1rem", fontWeight: 400, lineHeight: "150%" }}>
									{localLang.enterNameOfYourOfb}
								</Typography>
								{/* <Tooltip title={localLang.onceCreatedYouCannotChangeName}>
									<InfoOutlined
										sx={{
											color: theme.palette.mode == "dark" ? "#FFFFFF" : "#0000008A",
											height: "1.25rem",
											width: "1.25rem",
										}}
									/>
								</Tooltip> */}
							</TextContainer>
							<TextField
								label={localLang.ofbNameLable}
								placeholder={localLang.ofbNamePlaceholder}
								required={true}
								disabled={loading}
								value={propertyTitle}
								autoComplete="off"
								variant="outlined"
								onChange={(event: any) => {
									setPropertyTitle(event.target.value);
									setPropertyNameError(false);
								}}
								sx={{ width: "100%" }}
								error={propertyNameError}
								helperText={propertyNameError ? localLang.pleaseEnterName : ""}
							/>
						</TextContainer>
						{createBusinessProfile ? (
							<Typography
								variant="caption"
								sx={{ margin: "0.5rem 0rem 0rem 0rem" }}
							>
								{localLang.thisOfbWillBeListedUnderNewBP}
							</Typography>
						) : null}
					</CustomDiv>
				);
			case 1:
				return (
					<PropertyLocation
						dispatch={dispatch}
						newPropertyDataLocationCoordinates={newPropertyData?.search_metrics.location_coordinates}
						showMap={newPropertyData ? (newPropertyData.location.title.length > 0 ? true : false) : false}
						locationAddress={newPropertyData?.location.title}
					/>
				);
			case 2:
				return (
					<PropertyDetails
						property_transaction_type={newPropertyData?.transaction_type}
						property_category_type={newPropertyData?.property_category}
						property_type={newPropertyData?.property_type}
						property_subtype={newPropertyData?.property_subtype}
						property_min_price={newPropertyData?.price?.min}
						property_max_price={newPropertyData?.price?.max}
						property_security_deposit={newPropertyData?.security_deposit as security_deposit}
						property_availability={newPropertyData?.availability}
						property_possession_date={newPropertyData?.possession_date}
						property_unit_facing={
							newPropertyData?.unit_facing
								? newPropertyData?.unit_facing.map(
										(element: string) => element.charAt(0).toUpperCase() + element.slice(1).replace(/_/g, " "),
									)
								: []
						}
						property_land_facing={
							newPropertyData?.land_facing
								? newPropertyData?.land_facing.map(
										(element: string) => element.charAt(0).toUpperCase() + element.slice(1).replace(/_/g, " "),
									)
								: []
						}
						property_age={newPropertyData?.property_age}
						dispatch={dispatch}
						transactionTypeError={transactionTypeError}
						setTransactionTypeError={setTransactionTypeError}
						propertyCategoryError={propertyCategoryError}
						setPropertyCategoryError={setPropertyCategoryError}
						propertyTypeError={propertyTypeError}
						setPropertyTypeError={setPropertyTypeError}
						priceMinError={priceMinError}
						setPriceMinError={setPriceMinError}
						priceMaxError={priceMaxError}
						setPriceMaxError={setPriceMaxError}
						availabilityError={availabilityError}
						setAvailabilityError={setAvailabilityError}
						possessionDateError={possessionDateError}
						setPossessionDateError={setPossessionDateError}
					/>
				);
			case 3:
				return (
					<PropertyOverview
						overview_data={newPropertyData?.description}
						dispatch={dispatch}
					/>
				);
			case 4:
				return (
					<UploadImages
						newPropertyData={newPropertyData}
						dispatch={dispatch}
						session={session}
						businessProfiles={businessProfiles}
						isBusinessProfile={profile_context?.is_business_profile}
						isNewBusinessProfile={createBusinessProfile}
					/>
				);
			case 5:
				return (
					<PropertyCard
						profile_context={profile_context}
						session={session}
						dispatch={dispatch}
						loading={false}
						_id={""}
						slug={""}
						transaction_type={newPropertyData ? newPropertyData.transaction_type : ""}
						property_type={newPropertyData ? newPropertyData.property_type : [""]}
						title={propertyTitle}
						images={newPropertyData ? (newPropertyData.images.length > 0 ? newPropertyData.images[0].caption : "") : ""}
						property_status={newPropertyData ? newPropertyData.availability : [""]}
						possession_date={newPropertyData ? (newPropertyData.possession_date as string) : ""}
						location={newPropertyData ? (newPropertyData.location ? newPropertyData.location.title : "") : ""}
						developer={newPropertyData ? (newPropertyData.builder ? newPropertyData.builder.name : "") : ""}
						price={newPropertyData?.price}
						price_per_unit={newPropertyData?.price_per_unit}
						area={newPropertyData?.area}
						bhk={newPropertyData?.bhk}
						floors={newPropertyData?.floors}
						no_of_seats={newPropertyData?.no_of_seats}
						saved_content={newPropertyData?.saved_content}
						boosting={newPropertyData?.boost_information}
						created_by={newPropertyData?.created_by}
						show_contact_details={newPropertyData?.show_contact_details}
						published_status={newPropertyData?.status}
						ofb_category={newPropertyData?.property_category}
						preview={true}
					/>
				);
		}
	};

	return (
		<React.Fragment>
			<Snack
				open={openSnackbar}
				autoHideDuration={3000}
				onClose={() => setOpenSnackbar(false)}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<Alert
					onClose={() => setOpenSnackbar(false)}
					severity={newPropertyData?.snack_text === "Error while creating OFB" ? "error" : "success"}
					sx={{ width: "100%", borderRadius: "0.75rem" }}
					variant="filled"
				>
					{newPropertyData?.snack_text}
				</Alert>
			</Snack>

			<Dialog
				open={alertModal}
				maxWidth="sm"
				fullWidth={true}
				onClose={() => {
					setAlertModal(false);
				}}
			>
				<DialogTitle sx={{ fontSize: "1.25rem", fontWeight: 500, lineHeight: "160%" }}>
					{localLang.closeofbUpload}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>{localLang.unsavedDataWillBeLost}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<BeegruButton
						onClick={handleModalClose}
						color="primary"
						variant="outlined"
					>
						{localLang.yes}
					</BeegruButton>
					<BeegruButton
						onClick={() => {
							setAlertModal(false);
						}}
						color="primary"
						variant="contained"
					>
						{localLang.no}
					</BeegruButton>
				</DialogActions>
			</Dialog>

			<CustomDialog
				open={open}
				maxWidth="xs"
				fullWidth={true}
				fullScreen={smDevice}
				TransitionComponent={DialogTransition}
			>
				<CustomDialogTitle>
					<TextContainer sx={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
						<IconTitleRow>
							{!loading && activeStep > 0 ? (
								<IconButton
									aria-label="back"
									sx={{
										paddingLeft: "0rem",
									}}
									onClick={handleBack}
								>
									<ArrowBack fontSize="small" />
								</IconButton>
							) : null}

							<Typography variant="body1">
								{activeStep === 0
									? localLang.createNewOfb
									: activeStep === 1
										? localLang.location
										: activeStep === 2
											? localLang.details
											: activeStep === 3
												? localLang.addOverview
												: activeStep === 4
													? localLang.uploadImage
													: localLang.preview}
							</Typography>
						</IconTitleRow>

						<IconButton
							aria-label="close"
							onClick={() => {
								activeStep === 0 ? handleModalClose() : setAlertModal(true);
								/* Post OFB Button (intro page) */

								(pathname && pathname.query && pathname.query.user_post_ofb_button === "true") ||
								(pathname && pathname.query && pathname.query.agent_post_ofb_button === "true") ||
								(pathname && pathname.query && pathname.query.builder_post_ofb_button === "true") ||
								(pathname && pathname.query && pathname.query.landowner_post_ofb_button === "true") ||
								(pathname && pathname.query && pathname.query.professional_post_ofb_button === "true")
									? router.push((process.env.PRODUCTION_URL || "") + "/intro")
									: "";
							}}
						>
							{loading ? null : <Close fontSize="small" />}
						</IconButton>
					</TextContainer>

					<ProgressContainer>
						<CustomLinearProgress
							variant="determinate"
							value={(activeStep / 5) * 100}
						/>
					</ProgressContainer>
				</CustomDialogTitle>

				<CustomDialogContent
					sx={{
						justifyContent: activeStep === 5 ? "center" : "space-between",
						padding: activeStep === 2 ? "1rem 1rem 0rem 1rem" : "1rem 1rem 1rem 1rem",
					}}
				>
					{stepContent(activeStep)}

					{activeStep === 2 ? (
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-start",
								alignItems: "center",
								width: "100%",
							}}
						>
							<Typography
								sx={{
									fontSize: "0.875rem",
									fontWeight: 400,
									lineHeight: "150%",
									color: theme.palette.mode === "dark" ? "#FFFFFF" : "#9E9E9E",
								}}
							>
								{localLang.fieldsCannotBeEmpty}
							</Typography>
						</div>
					) : null}
				</CustomDialogContent>

				<CustomDiv
					sx={{
						padding: "0.5rem",
						borderRadius: smDevice ? "8px 8px 0px 0px" : "0px",
						boxShadow: smDevice
							? "rgba(0, 0, 0, 0.2) 0px 3px 3px 2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px"
							: "none",
					}}
				>
					{!loadNext ? (
						<TextContainer
							sx={{
								flexDirection: "row",
								width: "100%",
								justifyContent: smDevice ? "space-between" : "flex-end",
								alignItems: "center",
								gap: "1rem",
							}}
						>
							{/* {activeStep === 0 ? (
								<TextContainer sx={{ width: "8.75rem" }}></TextContainer>
							) : (
								<BeegruButton
									size="medium"
									flavor="primary"
									variant="text"
									onClick={handleBack}
									disabled={activeStep === 0 || loadNext}
									sx={{ width: "8.75rem" }}
								>
									{localLang.back}
								</BeegruButton>
							)} */}

							{/* <StepDiv>
								<CustomTypography>{activeStep + 1}/6</CustomTypography>
							</StepDiv> */}

							<BeegruButton
								size="medium"
								flavor="primary"
								variant="contained"
								fullWidth={smDevice}
								onClick={activeStep === 5 ? handleClickCreateNewProperty : handleNext}
								disabled={stepOneComplete || stepFourComplete}
								sx={{ minWidth: "8.75rem" }}
							>
								{activeStep === 4 ? localLang.preview : activeStep === 5 ? localLang.publish : localLang.next}
							</BeegruButton>
						</TextContainer>
					) : (
						<TextContainer sx={{ flexDirection: "row", width: "100%", justifyContent: "flex-end", gap: "1rem" }}>
							<Typography
								variant="body2"
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
									alignItems: "center",
									gap: "0.25rem",
									color: "primary.main",
								}}
							>
								{localLang.creatingOFB}
								<Circle>.</Circle>
								<Circle1>.</Circle1>
								<Circle2>.</Circle2>
							</Typography>
							<CircularProgress size={24} />
						</TextContainer>
					)}
				</CustomDiv>
			</CustomDialog>
		</React.Fragment>
	);
};

export default CreateNewOFBWithUploadTool;
