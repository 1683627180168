/* Common Imports */

import { styled, useMediaQuery } from "@mui/system";
import React from "react";

/* Redux Imports */

import StateInterface from "../../../../redux-magic/state-interface";
import { setProfileContextThunk } from "../../../../redux-magic/thunks";

/* Component Imports */

import {
	Alert,
	FormControlLabel,
	Link,
	Radio,
	RadioGroup,
	// ClickAwayListener,
	Snackbar,
	SvgIcon,
	SwipeableDrawer,
	Switch,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import CreateBusinessModal from "../../../modals/BusinessProfileModals/CreateBusinessProfileModal";
import ColorModeContext from "../../contexts/colorModeContext";

/* Icon Imports */

import {
	DarkMode,
	ExpandMore,
	Language,
	LightMode,
	LiveHelpOutlined,
	Logout,
	SettingsOutlined,
} from "@mui/icons-material";
import PremiumBadge from "../../../../public/images/badges/premium.svg";
import VerifiedPremiumBadge from "../../../../public/images/badges/verified_premium.svg";
import Points from "../../../../public/images/icons/beegru-points/beegru-points-logo.svg";
import Initials from "../../../cards/Initials";

/* Authentication Imports */

import { signOut } from "next-auth/react";

/* Routing Imports */

import { useRouter } from "next/router";
import CapitalizeFirstLetter from "../../../../lib/CapitalizeFirstLetter";
import BeegruButton from "../../../common-components/buttons/BeegruButton";
import BottomBuyBeegruPointsModal from "../../../modals/BottomBuyBeegruPointsModal";
import BuyBeegruPointsModal from "../../../modals/BuyBeegruPointsModal";

/* Styled Components */

const ContentContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem",
}));

const ContentInnerContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	padding: "1rem 1rem 1rem 1rem",
}));

const ContentRow = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	padding: "0.5rem 0rem 0rem 0rem",
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "0.9rem",
	fontWeight: 400,
}));

const Text = styled(Typography)(({ theme }) => ({
	width: "calc(100% - 2rem)",
	fontSize: "0.875rem",
	fontWeight: 400,
	lineHeight: "1.5rem",
	textOverflow: "ellipsis",
}));

const Links = styled(Link)(({ theme }) => ({
	fontSize: "0.8rem",
	fontWeight: 400,
	lineHeight: "1.5rem",
	textDecoration: "none",
	color: theme.palette.mode == "dark" ? "#fff" : "#000000",
}));

const ProfilePicture = styled("img")(({ theme }) => ({
	width: "2.65rem",
	height: "2.65rem",
	borderRadius: "8px",
	objectFit: "cover",
}));

const LoadingContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	padding: "1rem",
}));

const ToolTipDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
}));

const CustomSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
	"& .MuiDrawer-paper": {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "space-between",
		padding: "0rem",
		gap: "0rem",
		width: "100%",
	},
}));

// const UnordredList = styled("ul")(({ theme }) => ({
//   margin: 0,
//   paddingLeft: "1.5rem",
// }));

const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion
		disableGutters
		elevation={0}
		square
		{...props}
	/>
))(({ theme }) => ({
	// border: `1px solid ${theme.palette.divider}`,
	// "&:not(:last-child)": {
	//   borderBottom: 0,
	// },
	"&:before": {
		display: "none",
	},
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary
		expandIcon={
			<ExpandMore
				sx={{
					fontSize: "1.5rem",
					margin: "0rem 0rem 0rem 0rem",
					color: "#F6802A",
				}}
			/>
		}
		{...props}
	/>
))(({ theme }) => ({
	border: "none",
	minHeight: "auto",
	background: theme.palette.background.paper,
	flexDirection: "row",
	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
		transform: "rotate(-180deg)",
	},
	"& .MuiAccordionSummary-content": {
		margin: "0rem 0rem 0rem 0.3rem",
	},
	"& .MuiAccordionSummary-content p": {
		fontSize: "0.875rem",
		fontWeight: 400,
		lineHeight: "1.5rem",
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: "0rem 0rem 0.5rem 1rem",
	fontSize: "0.9rem",
}));

const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
	width: "100%",
	marginRight: "0rem",
	"& .MuiRadio-root": {
		width: "1rem",
		height: "1rem",
		margin: "0.4rem 0.6rem 0.4rem 0rem",
	},
}));

const TypeWithNameContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
}));

const TypeWithNameLink = styled(Link)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
	textDecoration: "none",
	color: theme.palette.mode == "dark" ? "#fff" : "#000000",
	"&:hover": {
		color: theme.palette.primary.main,
	},
}));

const NameBadgeContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
	width: "100%",
}));

const TypeTypo = styled(Typography)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	gap: "0.25rem",
	alignItems: "center",
	width: "100%",
	fontWeight: "400",
	fontSize: "0.8rem",
	color: theme.palette.mode == "dark" ? "#D3D3D3" : "#36454F",
}));

const RadioWithLink = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
	gap: "0rem",
	padding: "0.25rem 0rem 0.25rem 0rem",
}));

const SubDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	gap: "0.35rem",
	overflow: "hidden",
}));

const TextContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
	gap: "0.25rem",
}));

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
	width: 62,
	height: 34,
	padding: 7,
	"& .MuiSwitch-switchBase": {
		margin: 1,
		padding: 0,
		transform: "translateX(6px)",
		"&.Mui-checked": {
			color: "#FFFFFF",
			transform: "translateX(22px)",
			"& .MuiSwitch-thumb:before": {
				backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
					theme.palette.primary.main,
				)}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
			},
			"& + .MuiSwitch-track": {
				opacity: 1,
				backgroundColor: theme.palette.secondary.dark,
			},
		},
	},
	"& .MuiSwitch-thumb": {
		backgroundColor: theme.palette.background.paper,
		width: 32,
		height: 32,
		border: `1px solid ${theme.palette.primary.main}`,
		borderRadius: "12px",
		boxShadow: "none",
		"&:before": {
			content: "''",
			position: "absolute",
			width: "100%",
			height: "100%",
			left: 0,
			top: 0,
			backgroundRepeat: "no-repeat",
			backgroundPosition: "center",
			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
				theme.palette.primary.main,
			)}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
		},
	},
	"& .MuiSwitch-track": {
		opacity: 1,
		backgroundColor: theme.palette.mode === "dark" ? "#333" : "#AAB4BE",
		borderRadius: "16px",
	},
}));

const UserContent = ({
	session,
	dispatch,
	profilePicture,
	profile_context,
	new_business_profile_url,
	redux_session_updated,
	bottom_buy_subscription,
	UserprofilePicture,
}: {
	session: any;
	dispatch: Function;
	profilePicture: string;
	profile_context: StateInterface["profile_context"];
	new_business_profile_url: StateInterface["new_business_profile_url"];
	redux_session_updated?: boolean;
	bottom_buy_subscription: StateInterface["bottom_subscribe_buy_beegru_points"];
	UserprofilePicture: any;
}) => {
	const router = useRouter();
	const theme = useTheme();

	const signIn = () => {
		window.location.href = "/" + router.locale + "/auth/login?callbackUrl=" + encodeURIComponent(window.location.href);
	};

	const smDevice = useMediaQuery(theme.breakpoints.down("sm"));

	const [openbasic, setOpenbasic] = React.useState(false);

	const [openPostMenu, setOpenPostMenu] = React.useState<boolean>(false);

	const handleOpen = () => {
		if (smDevice) {
			setOpenPostMenu(true);
		} else {
			setOpenbasic(!openbasic);
		}
	};

	const onClose = () => {
		setOpenPostMenu(false);
	};

	const handleClose = () => {
		setOpenbasic(false);
	};

	const [open, setOpen] = React.useState(false);
	const pathname = React.useRef(router).current;

	React.useEffect(() => {
		if (pathname.query.create_business_profile === "true" && redux_session_updated === true) {
			redux_session_updated === true && session && session.authorized ? setOpen(true) : signIn();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [redux_session_updated]);
	React.useEffect(() => {
		if (pathname.query.create_business_profile === "true" && redux_session_updated === true && open === false) {
			router.push("");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);
	const [loading, setLoading] = React.useState(false);
	const [openProfileContextSnackbar, setOpenProfileContextSnackbar] = React.useState(false);
	const [expanded, setExpanded] = React.useState<boolean>(true);
	// const [showMore, setShowMore] = React.useState<Boolean>(false);

	// const [countrySelect, setcountrySelect] = React.useState("india");
	// const [langaugeSelect, setLangaugeSelect] = React.useState("");
	// const [currency, setCurrency] = React.useState("");

	let businessProfiles: Array<any> = session
		? session.user
			? session.user.dbUser
				? session.user.dbUser.pages
					? session.user.dbUser.pages
					: []
				: []
			: []
		: [];

	// let businessProfilesCount = session
	// 	? session.user
	// 		? session.user.dbUser
	// 			? session.user.dbUser.business_profiles_count
	// 				? session.user.dbUser.business_profiles_count
	// 				: 0
	// 			: 0
	// 		: 0
	// 	: 0;

	const session_user = session ? (session.user ? session.user.dbUser : "") : "";

	const [radio, setRadio] = React.useState(
		profile_context && profile_context.is_business_profile
			? profile_context && profile_context.business_profile_id
			: profile_context && profile_context.user_id,
	);

	/* Create new business profile modal functions */

	// const handleOpenBusinessProfile = () => {
	// 	setOpen(!open);
	// };

	const handleCloseBusinessProfile = () => {
		setOpen(false);
	};

	/* Change profile context function */

	const onChangeProfileContext = async (id: string) => {
		setLoading(true);
		await dispatch(
			setProfileContextThunk({
				is_business_profile: id === session_user._id ? false : true,
				user_id: session_user ? session_user._id : "",
				business_profile_id: id,
				page_type:
					id === session_user._id ? "user" : businessProfiles.find((item: any) => item.page_id === id).page_type,
				user_kyc_status: session.user.dbUser.kyc_info.kyc_status,
				user_subscription_information: session.user.dbUser.subscription_information,
				remember_choice: true,
				sessionId: session ? (session.session_id ? session.session_id : "") : "",
			}),
		);
		setLoading(false);
		setOpenProfileContextSnackbar(true);
	};

	/* Show more business profiles */

	// const handleClickShowMoreBusinessProfiles = async () => {
	// 	setLoading(true);
	// 	await dispatch(
	// 		FetchMoreBusinessProfilesThunk({
	// 			user_id: session_user ? session_user._id : "",
	// 			pages: businessProfiles.map((page: any) => {
	// 				return { page_id: page.page_id };
	// 			}),
	// 		}),
	// 	);
	// 	setLoading(false);
	// };

	const typeOfProfile = businessProfiles
		? businessProfiles.find((item: any) => item.page_id === radio) &&
			businessProfiles.find((item: any) => item.page_id === radio).page_type
		: "";

	const typeOfProfileSlug = businessProfiles
		? businessProfiles.find((item: any) => item.page_id === radio) &&
			businessProfiles.find((item: any) => item.page_id === radio).page_slug
		: "";

	const userName =
		session.user.dbUser.first_name !== ""
			? session.user.dbUser.first_name + " " + session.user.dbUser.last_name
			: session.user.dbUser.phone;

	const InitialsUserName =
		session.user.dbUser.first_name !== ""
			? session.user.dbUser.first_name + " " + session.user.dbUser.last_name
			: "Beegru";

	React.useEffect(() => {
		setRadio(
			profile_context && profile_context.is_business_profile
				? profile_context && profile_context.business_profile_id
				: profile_context && profile_context.user_id,
		);
	}, [profile_context]);

	/* Business profile types */

	// let bp_select =
	// 	profile_context && profile_context.is_business_profile
	// 		? businessProfiles.find((item: any) => item.page_id === radio) &&
	// 			businessProfiles.find((item: any) => item.page_id === radio)
	// 		: "user";

	const localLang = {
		snackValue:
			router.locale === "ar-AE" ? "تم التبديل إلى" : router.locale === "kn-IN" ? "ಗೆ ಬದಲಾಯಿಸಲಾಗಿದೆ" : "Switched to",
	};

	/* user redirect link  */
	const profileUrl =
		profile_context && profile_context.is_business_profile
			? `/${router.locale}/${typeOfProfile}s/${typeOfProfileSlug}`
			: `/${router.locale}/users/${session?.user?.dbUser?.slug || session?.user?.dbUser?._id || ""}`;

	const loadingUrl = `${process.env.PRODUCTION_URL}loading.html?callbackUrl=${encodeURIComponent(profileUrl)}`;

	/* state to handle language change */

	const [expandedLanguage, setExpandedLanguage] = React.useState(false);

	const [radioLanguage, setRadioLanguage] = React.useState(router.locale);

	// const handleLanguageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
	// 	const { pathname, asPath, query } = router;
	// 	router.push({ pathname, query }, asPath, { locale: event.target.value });
	// };

	const handleLanguageUrl = (locale: string) => {
		const baseUrl = process.env.PRODUCTION_URL || window.location.origin;
		const callbackUrl = `${baseUrl}${encodeURIComponent(locale || "en-IN")}`;
		const loadingUrl = `${baseUrl}/loading.html?callbackUrl=${encodeURIComponent(callbackUrl)}`;

		window.location.href = loadingUrl;
	};

	const user_id = session
		? session.user
			? session.user.dbUser
				? session.user.dbUser._id
					? session.user.dbUser._id
					: ""
				: ""
			: ""
		: "";

	const name = session
		? session.user
			? session.user.dbUser
				? session.user.dbUser.first_name
					? session.user.dbUser.first_name
					: "new" + "" + session.user.dbUser.last_name
						? session.user.dbUser.last_name
						: "user"
				: ""
			: ""
		: "";

	const country_code = session
		? session.user
			? session.user.dbUser
				? session.user.dbUser.country_code
				: ""
			: ""
		: "";

	const phone = session
		? session.user
			? session.user.dbUser
				? session.user.dbUser.phone
					? session.user.dbUser.phone
					: ""
				: ""
			: ""
		: "";

	const email = session
		? session.user
			? session.user.dbUser
				? session.user.dbUser.recovery_email
					? session.user.dbUser.recovery_email.email
					: ""
				: ""
			: ""
		: "";

	const available_beegru_points = session
		? session.user
			? session.user.dbUser
				? session.user.dbUser.beegru_points
					? session.user.dbUser.beegru_points
					: 0
				: 0
			: 0
		: 0;

	return (
		<React.Fragment>
			{/* Profile context snackbar */}
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={openProfileContextSnackbar}
				autoHideDuration={3000}
				onClose={() => {
					setOpenProfileContextSnackbar(false);
				}}
				sx={{
					borderRadius: "0.5rem",
				}}
			>
				<Alert
					onClose={() => {
						setOpenProfileContextSnackbar(false);
					}}
					severity="success"
					variant="filled"
					sx={{ width: "100%", borderRadius: "0.75rem" }}
				>
					{/* {router.locale === "kn-IN" || router.locale === "ar-AE"
						? ""
						: localLang.snackValue +
									(profile_context?.is_business_profile
										? businessProfiles.find((item: any) => item.page_id === profile_context?.business_profile_id)
												?.page_title
										: userName) +
									router.locale ===
									"kn-IN" || router.locale === "ar-AE"
							? localLang.snackValue
							: ""} */}
					{"Switched to " +
						(profile_context?.is_business_profile
							? businessProfiles.find((item: any) => item.page_id === profile_context?.business_profile_id)?.page_title
							: userName)}
				</Alert>
			</Snackbar>

			{/* Create Business Profile Modal */}

			<CreateBusinessModal
				open={open}
				onClose={handleCloseBusinessProfile}
				session={session}
				dispatch={dispatch}
				new_business_profile_url={new_business_profile_url}
			/>

			{/* Buy Beegru Point Mobile view modal */}

			<CustomSwipeableDrawer
				anchor="bottom"
				open={openPostMenu}
				onClose={onClose}
				onOpen={() => setOpenPostMenu(true)}
				swipeAreaWidth={156}
				disableSwipeToOpen={true}
				ModalProps={{
					keepMounted: true,
				}}
				sx={{
					"& .MuiDrawer-paper": {
						background: theme.palette.background.paper,
						height: "100%",
						zIndex: 1500,
					},
					zIndex: 1500,
				}}
			>
				<BottomBuyBeegruPointsModal
					name={name}
					email={email}
					phone={phone}
					user_id={user_id}
					open={openbasic}
					onClose={onClose}
					bottom_buy_subscription={bottom_buy_subscription}
					dispatch={dispatch}
					listing_tabValue={0}
					available_beegru_points={Number(available_beegru_points?.toFixed(2))}
				/>
			</CustomSwipeableDrawer>

			{/* Buy beegru point modal */}

			<BuyBeegruPointsModal
				user_id={user_id}
				name={name}
				email={email}
				phone={"+" + country_code + phone}
				open={openbasic}
				onClose={handleClose}
				bottom_buy_subscription={bottom_buy_subscription}
				dispatch={dispatch}
				available_beegru_points={available_beegru_points}
			/>

			{/* User Profile */}
			<Links
				href={loadingUrl}
				rel="noopener"
				target="_self"
				referrerPolicy="no-referrer"
			>
				<ContentContainer
					sx={{
						borderBottom: "1px solid #0000001F",
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-start",
						alignItems: "center",
						gap: "1rem",
					}}
				>
					{!profilePicture.includes("/images/icons/Avatar.svg") ? (
						<ProfilePicture
							src={`/profile-picture/${profilePicture}`}
							alt="user"
							referrerPolicy="no-referrer"
						/>
					) : (
						<Initials
							title={
								profile_context
									? profile_context.is_business_profile
										? profile_context.page_title
										: InitialsUserName
									: ""
							}
							profiledropdown={true}
							header={false}
							comment={false}
							postcard={false}
							businessprofile={false}
						/>
					)}
					<TypeWithNameContainer>
						<NameBadgeContainer>
							<Heading
								variant="h6"
								sx={{ fontSize: "1rem" }}
							>
								{profile_context
									? profile_context.is_business_profile
										? profile_context.page_title
										: session.user.dbUser.first_name
											? session.user.dbUser.first_name + " " + session.user.dbUser.last_name
											: session.user.dbUser.phone
									: ""}
							</Heading>

							{profile_context ? (
								profile_context.kyc_info?.kyc_status !== "kyc_completed" &&
								profile_context.subscription_information &&
								profile_context.subscription_information.active ? (
									<Tooltip title="Premium">
										<ToolTipDiv>
											<SvgIcon
												component={PremiumBadge}
												inheritViewBox={false}
												viewBox="0 0 24 24"
												width={24}
												height={24}
												sx={{
													width: "0.95rem",
													height: "0.95rem",
												}}
											/>
										</ToolTipDiv>
									</Tooltip>
								) : profile_context.kyc_info?.kyc_status === "kyc_completed" &&
								  profile_context.subscription_information &&
								  profile_context.subscription_information.active ? (
									<Tooltip title="Verified Premium">
										<ToolTipDiv>
											<SvgIcon
												component={VerifiedPremiumBadge}
												inheritViewBox={false}
												viewBox="0 0 24 24"
												width={24}
												height={24}
												sx={{
													width: "0.95rem",
													height: "0.95rem",
												}}
											/>
										</ToolTipDiv>
									</Tooltip>
								) : null
							) : null}
						</NameBadgeContainer>
						<Heading sx={{ fontSize: "0.8rem", color: "#666666" }}>{country_code + " " + phone}</Heading>

						<TypeTypo>
							{profile_context
								? profile_context.page_type === "user"
									? ""
									: profile_context.page_type === "agent"
										? router.locale === "ar-AE"
											? "وكلاء العقارات"
											: router.locale === "kn-IN"
												? "ಏಜೆಂಟ್"
												: "Agent"
										: profile_context.page_type === "developer"
											? router.locale === "ar-AE"
												? "المطور"
												: router.locale === "kn-IN"
													? "ಡೆವಲಪರ್"
													: "Developer"
											: profile_context.page_type === "landowner"
												? router.locale === "ar-AE"
													? "مالك الأرض"
													: router.locale === "kn-IN"
														? "ಭೂಮಿಯ ಮಾಲಿಕ"
														: "Landowner"
												: profile_context.page_type === "professional"
													? router.locale === "ar-AE"
														? "محترف الخدمة"
														: router.locale === "kn-IN"
															? "ಪ್ರೊಫೆಶನಲ್"
															: "Service Professional"
													: CapitalizeFirstLetter(profile_context.page_type)
								: ""}
						</TypeTypo>
					</TypeWithNameContainer>
				</ContentContainer>
			</Links>

			<ContentInnerContainer>
				{/* Total Beegru Points */}

				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-start",
						alignItems: "flex-start",
						gap: "0.25rem",
					}}
				>
					<TextContainer>
						<Typography
							variant="subtitle2"
							sx={{
								padding: "0rem 0rem 0rem 0rem",
								color: "#666666",
								fontSize: "0.875rem",
								lineHeight: "1.35rem",
							}}
						>
							{router.locale === "ar-AE"
								? "نقاط بيجرو"
								: router.locale === "kn-IN"
									? "ಬೀಗ್ರು ಪಾಯಿಂಟ್"
									: "Beegru Points"}
						</Typography>
					</TextContainer>
					<Links
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-start",
							alignItems: "center",
							gap: "0.5rem",
						}}
						href={`${process.env.PRODUCTION_URL}loading.html?callbackUrl=${encodeURIComponent(`/${router.locale}/users/${session?.user?.dbUser?.slug || session?.user?.dbUser?._id || ""}/account-settings`)}`}
						rel="noopener"
						target="_self"
						referrerPolicy="no-referrer"
					>
						<SvgIcon
							component={Points}
							inheritViewBox={false}
							viewBox="0 0 24 24"
							width={24}
							height={24}
							sx={{
								width: "1.25rem",
								height: "1.25rem",
							}}
						/>
						<Text
							variant="h6"
							sx={{ fontSize: "1.25rem" }}
						>
							{session.user.dbUser.beegru_points ? session.user.dbUser.beegru_points.toFixed(1) : 0}
						</Text>
					</Links>
				</div>

				<BeegruButton
					variant="contained"
					size="medium"
					sx={{
						"&.MuiButton-contained": {
							background: "rgba(252, 128, 25, 0.16)",
							color: "#B05911",
							"&:hover": {
								background: "rgba(252, 128, 25, 0.16)",
								color: "#B05911",
							},
						},
					}}
					onClick={handleOpen}
				>
					{router.locale === "kn-IN" ? "ಹೆಚ್ಚು ಖರೀದಿಸಿ" : "Buy more"}
				</BeegruButton>

				{/* </PointsContainer> */}

				{/* <PointsContainer>
					<SubDiv>
						<Links
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-start",
								alignItems: "center",
								gap: "0.35rem",
							}}
							href={`${process.env.PRODUCTION_URL}loading.html?callbackUrl=${encodeURIComponent(`/${router.locale}/users/${session?.user?.dbUser?.slug || session?.user?.dbUser?._id || ""}/account-settings`)}`}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							<SvgIcon
								component={Points}
								inheritViewBox={false}
								viewBox="0 0 24 24"
								width={24}
								height={24}
								sx={{
									width: "1.5rem",
									height: "1.5rem",
								}}
							/>
							<Text sx={{ fontSize: "1.25rem" }}>
								{session.user.dbUser.beegru_points ? session.user.dbUser.beegru_points.toFixed(1) : 0}
							</Text>
						</Links>

						<Tooltip
							title={
								router.locale === "ar-AE"
									? "يشمل ذلك (نقاط بيجرو المشتراة + نقاط بيجرو المكتسبة)"
									: router.locale === "kn-IN"
										? "ಇದರಲ್ಲಿ ನಿಮ್ಮ (ಖರೀದಿಸಿದ ಬೀಗ್ರು ಪಾಯಿಂಟ್‌ಗಳು + ಗಳಿಸಿದ ಬೀಗ್ರು ಪಾಯಿಂಟ್‌ಗಳು)"
										: "This includes your (Purchased Beegru Points + Earned Beegru Points)"
							}
						>
							<InfoOutlined sx={{ fontSize: "0.875rem" }} />
						</Tooltip>
					</SubDiv>
					<Typography
						variant="caption"
						sx={{
							padding: "0rem 0rem 0rem 0rem",
						}}
					>
						{router.locale === "ar-AE" ? "نقاط بيجرو" : router.locale === "kn-IN" ? "ಬೀಗ್ರು ಪಾಯಿಂಟ್" : "Beegru Points"}
					</Typography>
				</PointsContainer>

				 <div
					style={{
						margin: "0rem 0rem 0rem 0.5rem",
						borderRight: "1px solid #E0E0E0",
						height: "3.5rem",
					}}
				/>  */}

				{/* Earned Beegru Points */}

				{/* <PointsContainer sx={{ margin: "0rem 0rem 0rem 0.5rem" }}>
					<SubDiv>
						<Links
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-start",
								alignItems: "center",
								gap: "0.35rem",
							}}
							href={`${process.env.PRODUCTION_URL}loading.html?callbackUrl=${encodeURIComponent(`/${router.locale}/users/${session?.user?.dbUser?.slug || session?.user?.dbUser?._id || ""}/account-settings`)}`}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
						>
							<SvgIcon
								component={Points}
								inheritViewBox={false}
								viewBox="0 0 24 24"
								width={24}
								height={24}
								sx={{
									width: "1.5rem",
									height: "1.5rem",
								}}
							/>
							<Text sx={{ width: "100%", fontSize: "1.25rem" }}>
								{session.user.dbUser.earned_beegru_points ? session.user.dbUser.earned_beegru_points.toFixed(1) : 0}
							</Text>
						</Links>

						<Tooltip
							title={
								router.locale === "ar-AE"
									? "هذه هي نقاط بيجرو التي اكتسبتها لمجرد بقائك نشطًا على المنصة"
									: router.locale === "kn-IN"
										? "ಪ್ಲಾಟ್‌ಫಾರ್ಮ್‌ನಲ್ಲಿ ಸಕ್ರಿಯವಾಗಿರುವ ಮೂಲಕ ನೀವು ಗಳಿಸಿದ ಬೀಗ್ರು ಪಾಯಿಂಟ್‌ಗಳು ಇವು"
										: "These are the Beegru Points that you earned just by staying active on the platform"
							}
						>
							<InfoOutlined sx={{ fontSize: "0.875rem" }} />
						</Tooltip>
					</SubDiv>
					<Typography
						variant="caption"
						sx={{
							padding: "0rem 0rem 0rem 0rem",
						}}
					>
						{router.locale === "ar-AE"
							? "النقاط المكتسبة"
							: router.locale === "kn-IN"
								? "ಗಳಿಸಿದ ಅಂಕಗಳು"
								: "Earned points"}
					</Typography>
				</PointsContainer> */}
			</ContentInnerContainer>
			{/* Dark Mode Switch */}
			{session?.user?.dbUser?.access_level.includes("super_admin") ? (
				<ContentContainer
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						padding: "1rem 1rem 0rem 0.8rem",
						gap: "0.5rem",
					}}
				>
					{theme.palette.mode === "dark" ? <DarkMode /> : <LightMode sx={{ color: "#757575" }} />}
					<Text variant="h6">
						{theme.palette.mode === "dark"
							? router.locale === "ar-AE"
								? "التبديل إلى الوضع الفاتح"
								: router.locale === "kn-IN"
									? "ಲೈಟ್ ಮೋಡ್‌ಗೆ ಬದಲಾಯಿಸಿ"
									: "Switch to Light Mode"
							: router.locale === "ar-AE"
								? "التبديل إلى الوضع المظلم"
								: router.locale === "kn-IN"
									? "ಡಾರ್ಕ್ ಮೋಡ್‌ಗೆ ಬದಲಾಯಿಸಿ"
									: "Switch to Dark Mode"}
					</Text>
					<ColorModeContext.Consumer>
						{({ toggleColorMode }) => (
							<FormControlLabel
								onChange={toggleColorMode}
								control={<MaterialUISwitch checked={theme.palette.mode === "dark" ? true : false} />}
								label=""
								labelPlacement="start"
							/>
						)}
					</ColorModeContext.Consumer>
				</ContentContainer>
			) : null}
			{/* Languages */}
			<ContentContainer
				sx={{
					padding: "1rem 1rem 0rem 0.8rem",
					borderTop: "1px solid #E0E0E0",
				}}
			>
				<Accordion
					expanded={expandedLanguage}
					onChange={() => setExpandedLanguage(!expandedLanguage)}
					sx={{
						width: "100%",
						background: theme.palette.background.paper,
						padding: "0rem",
						margin: "0rem",
						border: "none",
					}}
				>
					<AccordionSummary
						aria-controls="panel1d-content"
						id="panel1d-header"
						sx={{
							padding: expanded === true ? "0rem 0rem 0.5rem 0rem" : "0rem 0rem 0.5rem 0rem",
						}}
					>
						<div style={{ display: "flex", alignItems: "center" }}>
							<Language
								sx={theme.palette.mode === "dark" ? { color: "#FFFFFF" } : { color: "#FC8019" }}
								fontSize="small"
							/>
							<Text sx={{ padding: router.locale === "ar-AE" ? "0rem 0.5rem 0rem 0rem" : "0rem 0rem 0rem 0.5rem" }}>
								{router.locale === "ar-AE" ? "العربية" : router.locale === "kn-IN" ? "ಕನ್ನಡ" : "English"}
							</Text>
						</div>
					</AccordionSummary>
					<AccordionDetails>
						<RadioGroup
							aria-labelledby="your-profiles"
							name="your-profiles"
							sx={{
								// margin: "0.5rem 0rem 0rem 0.5rem",
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-end",
								alignItems: "center",
								width: "100%",
							}}
							value={radioLanguage}
							onChange={(e) => {
								setRadioLanguage(e.target.value);
							}}
						>
							<CustomFormControlLabel
								value="en-IN"
								control={<Radio size="small" />}
								label={<Heading>English</Heading>}
								onClick={() => handleLanguageUrl("en-IN")}
								sx={{ padding: "0.5rem 0rem 0.5rem 0rem", justifyContent: "space-between", width: "100%" }}
								labelPlacement="start"
							/>
							<CustomFormControlLabel
								value="kn-IN"
								control={<Radio size="small" />}
								label={<Heading>ಕನ್ನಡ</Heading>}
								onClick={() => handleLanguageUrl("kn-IN")}
								sx={{ padding: "0.5rem 0rem 0.5rem 0rem", justifyContent: "space-between", width: "100%" }}
								labelPlacement="start"
							/>
							{/* <CustomFormControlLabel
								value="ar-AE"
								control={<Radio size="small" />}
								label={<Heading>العربية</Heading>}
								onClick={() => handleLanguageUrl("ar-AE")}
								sx={{ padding: "0.5rem 0rem 0.5rem 0rem", justifyContent: "space-between", width: "100%" }}
								labelPlacement="start"
							/> */}
						</RadioGroup>
					</AccordionDetails>
				</Accordion>
			</ContentContainer>
			{/* Your Profiles */}
			{/* {!loading ? (
				<ContentContainer
					sx={{
						padding: "1rem 1rem 0rem 0.8rem",
					}}
				>
					<Accordion
						expanded={expanded}
						onChange={() => setExpanded(!expanded)}
						sx={{
							width: "100%",
							background: theme.palette.background.paper,
							padding: "0rem",
							margin: "0rem",
							border: "none",
						}}
					>
						<AccordionSummary
							aria-controls="panel1d-content"
							id="panel1d-header"
							sx={{
								padding: expanded === true ? "0rem 0rem 0.5rem 0rem" : "0rem 0rem 0.5rem 0rem",
							}}
						>
							<ManageAccountsOutlined
								sx={theme.palette.mode === "dark" ? { color: "#FFFFFF" } : { color: "#757575" }}
							/>
							<Text sx={{ padding: router.locale === "ar-AE" ? "0rem 0.5rem 0rem 0rem" : "0rem 0rem 0rem 0.5rem" }}>
								{router.locale === "ar-AE"
									? "ملفاتك الشخصية"
									: router.locale === "kn-IN"
										? "ನಿಮ್ಮ ಪ್ರೊಫೈಲ್‌ಗಳು"
										: "Your profiles"}
							</Text>
						</AccordionSummary>

						<AccordionDetails>
							<RadioGroup
								aria-labelledby="your-profiles"
								name="your-profiles"
								sx={{
									margin: "0.5rem 0rem 0rem 0.5rem",
								}}
								value={radio}
								onChange={(e) => {
									setRadio(e.target.value);
									onChangeProfileContext(e.target.value);
								}}
							>
								<RadioWithLink>
									<CustomFormControlLabel
										value={session_user._id}
										control={<Radio size="small" />}
										label={
											<TypeWithNameLink
												href={`${process.env.PRODUCTION_URL}loading.html?callbackUrl=${encodeURIComponent(`/${router.locale}/users/${session_user.slug || session_user._id}`)}`}
												rel="noopener"
												target="_self"
												referrerPolicy="no-referrer"
												sx={{
													margin: router.locale === "ar-AE" ? "0rem 0.5rem 0rem 0rem" : "0rem 0rem 0rem 0rem",
												}}
											>
												<Heading sx={{ width: "100%" }}>{userName}</Heading>
												<TypeTypo>
													{router.locale === "ar-AE"
														? "الملف الشخصي للمستخدم"
														: router.locale === "kn-IN"
															? "ಬಳಕೆದಾರರ ಪ್ರೊಫೈಲ್"
															: "User profile"}
												</TypeTypo>
											</TypeWithNameLink>
										}
									/>
									<a
										href={`${process.env.PRODUCTION_URL}loading.html?callbackUrl=${encodeURIComponent(`/${router.locale}/users/${session_user.slug || session_user._id}`)}`}
										rel="noopener"
										target="_self"
										referrerPolicy="no-referrer"
										title="Open User Profile"
									>
										<OpenInNew
											sx={{
												fontSize: "1.5rem",
												color: theme.palette.primary.main,
											}}
										/>
									</a>
								</RadioWithLink>

								{businessProfiles.slice(0, 1).map((page: any, index: number) => (
									<RadioWithLink key={index}>
										<CustomFormControlLabel
											key={index}
											value={page.page_id}
											control={<Radio size="small" />}
											label={
												<TypeWithNameLink
													href={`${process.env.PRODUCTION_URL}loading.html?callbackUrl=${encodeURIComponent(`/${router.locale}/${page.page_type}s/${page.page_slug}`)}`}
													rel="noopener"
													target="_self"
													referrerPolicy="no-referrer"
													sx={{
														margin: router.locale === "ar-AE" ? "0rem 0.5rem 0rem 0rem" : "0rem 0rem 0rem 0rem",
													}}
												>
													<Heading>{page.page_title}</Heading>
													<TypeTypo>
														{page.page_type === "agent"
															? router.locale === "ar-AE"
																? "وكلاء العقارات"
																: router.locale === "kn-IN"
																	? "ಏಜೆಂಟ್"
																	: "Agent"
															: page.page_type === "developer"
																? router.locale === "ar-AE"
																	? "المطور"
																	: router.locale === "kn-IN"
																		? "ಡೆವಲಪರ್"
																		: "Developer"
																: page.page_type === "professional"
																	? router.locale === "ar-AE"
																		? "مالك الأرض"
																		: router.locale === "kn-IN"
																			? "ಭೂಮಿಯ ಮಾಲಿಕ"
																			: "Landowner"
																	: page.page_type === "professional"
																		? router.locale === "ar-AE"
																			? "محترف الخدمة"
																			: router.locale === "kn-IN"
																				? "ಪ್ರೊಫೆಶನಲ್"
																				: "Service Professional"
																		: page.page_type.charAt(0).toUpperCase() + page.page_type.slice(1)}
													</TypeTypo>
												</TypeWithNameLink>
											}
										/>
										<a
											href={`${process.env.PRODUCTION_URL}loading.html?callbackUrl=${encodeURIComponent(`/${router.locale}/${page.page_type}s/${page.page_slug}`)}`}
											rel="noopener"
											target="_self"
											referrerPolicy="no-referrer"
											title="Open Business Profile"
										>
											<OpenInNew
												sx={{
													fontSize: "1.5rem",
													color: theme.palette.primary.main,
												}}
											/>
										</a>
									</RadioWithLink>
								))}
							</RadioGroup>
						</AccordionDetails>
					</Accordion>
				</ContentContainer>
			) : (
				<LoadingContainer>
					<CircularProgress />
				</LoadingContainer>
			)} */}
			{/* Your Profiles

			{!loading ? (
				<ContentContainer
					sx={{
						padding: "1rem 1rem 0rem 0.8rem",
					}}
				>
					<Accordion
						expanded={expanded}
						onChange={() => setExpanded(!expanded)}
						sx={{
							width: "100%",
							background: theme.palette.background.paper,
							padding: "0rem",
							margin: "0rem",
							border: "none",
						}}
					>
						<AccordionSummary
							aria-controls="panel1d-content"
							id="panel1d-header"
							sx={{
								padding: expanded === true ? "0rem 0rem 0.5rem 0rem" : "0rem 0rem 0.5rem 0rem",
							}}
						>
							<ManageAccountsOutlined
								sx={theme.palette.mode === "dark" ? { color: "#FFFFFF" } : { color: "#757575" }}
							/>
							<Text sx={{ padding: "0rem 0rem 0rem 0.5rem" }}>Your profiles</Text>
						</AccordionSummary>

						<AccordionDetails>
							<RadioGroup
								aria-labelledby="your-profiles"
								name="your-profiles"
								sx={{
									margin: "0.5rem 0rem 0rem 0.5rem",
								}}
								value={radio}
								onChange={(e) => {
									setRadio(e.target.value);
									onChangeProfileContext(e.target.value);
								}}
							>
								<RadioWithLink>
									<CustomFormControlLabel
										value={session_user._id}
										control={<Radio size="small" />}
										label={
											<TypeWithNameLink
												href={"/users/" + (session_user.slug || session_user._id)}
												rel="noopener"
												target="_self"
												referrerPolicy="no-referrer"
											>
												<Heading sx={{ width: "100%" }}>{userName}</Heading>
												<TypeTypo>User</TypeTypo>
											</TypeWithNameLink>
										}
									/>
									<a
										href={"/users/" + (session_user.slug || session_user._id)}
										rel="noopener"
										target="_self"
										referrerPolicy="no-referrer"
									>
										<OpenInNew
											sx={{
												fontSize: "1.5rem",
												color: theme.palette.primary.main,
											}}
										/>
									</a>
								</RadioWithLink>

								{showMore
									? businessProfiles.map((page: any, index: number) => (
											<RadioWithLink key={index}>
												<CustomFormControlLabel
													key={index}
													value={page.page_id}
													control={<Radio size="small" />}
													label={
														<TypeWithNameLink
															href={"/" + page.page_type + "s" + "/" + page.page_slug}
															rel="noopener"
															target="_self"
															referrerPolicy="no-referrer"
														>
															<Heading>{page.page_title}</Heading>
															<TypeTypo>{page.page_type.charAt(0).toUpperCase() + page.page_type.slice(1)}</TypeTypo>
														</TypeWithNameLink>
													}
												/>
												<a
													href={"/" + page.page_type + "s" + "/" + page.page_slug}
													rel="noopener"
													target="_self"
													referrerPolicy="no-referrer"
												>
													<OpenInNew
														sx={{
															fontSize: "1.5rem",
															color: theme.palette.primary.main,
														}}
													/>
												</a>
											</RadioWithLink>
										))
									: businessProfiles.slice(0, 2).map((page: any, index: number) => (
											<RadioWithLink key={index}>
												<CustomFormControlLabel
													key={index}
													value={page.page_id}
													control={<Radio size="small" />}
													label={
														<TypeWithNameLink
															href={"/" + page.page_type + "s" + "/" + page.page_slug}
															rel="noopener"
															target="_self"
															referrerPolicy="no-referrer"
														>
															<Heading>{page.page_title}</Heading>
															<TypeTypo>{page.page_type.charAt(0).toUpperCase() + page.page_type.slice(1)}</TypeTypo>
														</TypeWithNameLink>
													}
												/>
												<a
													href={"/" + page.page_type + "s" + "/" + page.page_slug}
													rel="noopener"
													target="_self"
													referrerPolicy="no-referrer"
												>
													<OpenInNew
														sx={{
															fontSize: "1.5rem",
															color: theme.palette.primary.main,
														}}
													/>
												</a>
											</RadioWithLink>
										))}
								{businessProfilesCount > 2 && (
									<ShowMore
										onClick={() => {
											if (businessProfiles.length < businessProfilesCount) {
												handleClickShowMoreBusinessProfiles();
												setShowMore(!showMore);
											} else {
												setShowMore(!showMore);
											}
										}}
									>
										{showMore ? "Show less..." : "Show more..."}
									</ShowMore>
								)}
							</RadioGroup>

							<ContentRow
								onClick={handleOpenBusinessProfile}
								sx={{
									justifyContent: "flex-start",
									gap: "0.4rem",
									color: "#F6843B",
									cursor: "pointer",
								}}
							>
								<Add
									sx={{
										fontSize: "1rem",
										margin: "0rem 0rem 0rem 0rem",
										color: theme.palette.mode == "dark" ? "#ffffff" : "#575757",
									}}
								/>
								<Text
									variant="h6"
									sx={{
										color: theme.palette.mode == "dark" ? "#ffffff" : "#000000",
									}}
								>
									Create business profile
								</Text>
							</ContentRow>
						</AccordionDetails>
					</Accordion>
				</ContentContainer>
			) : (
				<LoadingContainer>
					<CircularProgress />
				</LoadingContainer>
			)} */}
			{/* Country Dropdown */}
			{/* <ContentContainer
				sx={{
					borderTop: "1px solid #E5E5E5",
					cursor: "pointer",
				}}
			>
				<CountrySelectDiv>
					<Select
						variant="outlined"
						labelId="demo-simple-select-standard-label"
						id="country-select"
						value={countrySelect}
						onChange={async (event: any) => {
							await dispatch(setLanguageThunk(event.target.value));
							setcountrySelect(event.target.value);
						}}
						// onChange={(e) => setcountrySelect(e.target.value)}
						label="Country"
						disableUnderline={true}
						MenuProps={{
							disableScrollLock: true,
							MenuListProps: {
								sx: {
									backgroundColor: theme.palette.mode === "dark" ? "#212B36" : "#FFFFFF",
								},
							},
						}}
						sx={{ width: 130 }}
					>
						<MenuItem value={"united-states"}>
							<Text variant="h6">United States</Text>
						</MenuItem>
						<MenuItem value={"united-kingdom"}>
							<Text variant="h6">United Kingdom</Text>
						</MenuItem>
						<MenuItem value={"india"}>
							<Text variant="h6">India</Text>
						</MenuItem>
						<MenuItem value={"singapore"}>
							<Text variant="h6">Singapore</Text>
						</MenuItem>
						<MenuItem value={"australia"}>
							<Text variant="h6">Australia</Text>
						</MenuItem>
					</Select>
					<Text variant="h6">
						{langaugeSelect} | {currency}
					</Text>
				</CountrySelectDiv>
			</ContentContainer> */}
			{/* Settings */}
			<Links
				href={`${process.env.PRODUCTION_URL || ""}loading.html?callbackUrl=${encodeURIComponent(
					`${process.env.PRODUCTION_URL || ""}/${router.locale}/users/${
						session?.user?.dbUser?.slug && session.user.dbUser.slug.length > 0
							? session.user.dbUser.slug
							: session?.user?.dbUser?._id || ""
					}/account-settings`,
				)}&locale=${router.locale || "en-IN"}`}
				rel="noopener"
				target="_self"
				referrerPolicy="no-referrer"
			>
				<ContentContainer
					sx={{
						// borderTop: "1px solid #E5E5E5",
						cursor: "pointer",

						padding: "0rem 1rem 0rem 1rem",
					}}
				>
					<ContentRow>
						<SettingsOutlined sx={theme.palette.mode === "dark" ? { color: "#FFFFFF" } : { color: "#FC8019" }} />
						<Text variant="h6">
							{router.locale === "ar-AE"
								? "الإعدادات"
								: router.locale === "kn-IN"
									? "ಸೆಟ್ಟಿಂಗ್‌ಗಳು"
									: "Account Settings"}
						</Text>
					</ContentRow>
				</ContentContainer>
			</Links>
			{/* FAQs */}
			<Links
				href={`${process.env.PRODUCTION_URL}loading.html?callbackUrl=${encodeURIComponent(`/${router.locale}/faqs`)}`}
				rel="noopener"
				target="_self"
				referrerPolicy="no-referrer"
			>
				<ContentContainer
					sx={{
						// borderTop: "1px solid #E5E5E5",
						cursor: "pointer",
					}}
				>
					<ContentRow>
						<LiveHelpOutlined sx={theme.palette.mode === "dark" ? { color: "#FFFFFF" } : { color: "#FC8019" }} />
						<Text variant="h6">
							{router.locale === "ar-AE" ? "الأسئلة الشائعة" : router.locale === "kn-IN" ? "ಎಫ್ಎಕ್ಯೂ" : "FAQ's"}
						</Text>
					</ContentRow>
				</ContentContainer>
			</Links>
			{/* Logout */}
			<ContentContainer
				sx={{
					cursor: "pointer",
					padding: "0rem 1rem 1rem 1rem",
				}}
				onClick={() => {
					signOut({
						callbackUrl:
							process.env.PRODUCTION_API_URL +
							"auth/end-session?sessionId=" +
							encodeURIComponent(session.session_id) +
							"&callbackUrl=" +
							encodeURIComponent(router.asPath),
					});
				}}
			>
				<ContentRow>
					<Logout sx={theme.palette.mode === "dark" ? { color: "#FFFFFF" } : { color: "#FC8019" }} />
					<Text variant="h6">
						{router.locale === "ar-AE" ? "تسجيل الخروج" : router.locale === "kn-IN" ? "ಲಾಗೌಟ್" : "Sign out"}
					</Text>
				</ContentRow>
			</ContentContainer>
			{/* switch profile */}

			<ContentContainer
				sx={{
					borderTop: "1px solid #E5E5E5",
					cursor: "pointer",
					padding: "0rem 1rem 1rem 1rem",
				}}
			>
				<ContentRow>
					<Text variant="h6">{router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "" : "Switch Account"}</Text>
				</ContentRow>

				<RadioGroup
					aria-labelledby="your-profiles"
					name="your-profiles"
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
						alignItems: "center",
						width: "100%",
					}}
					value={radio}
					onChange={(e) => {
						setRadio(e.target.value);
						onChangeProfileContext(e.target.value);
					}}
				>
					<RadioWithLink>
						{!UserprofilePicture.includes("/images/icons/Avatar.svg") ? (
							<ProfilePicture
								src={`/profile-picture/${UserprofilePicture}`}
								alt={session ? session.user.dbUser.first_name.charAt(0).toUpperCase() : null}
								referrerPolicy="no-referrer"
								loading="lazy"
							/>
						) : (
							<Initials
								title={session ? session.user.dbUser.first_name + " " + session.user.dbUser.last_name : ""}
								profiledropdown={false}
								header={false}
								comment={false}
								postcard={false}
								businessprofile={false}
								selectProfile={true}
							/>
						)}
						<CustomFormControlLabel
							value={session_user._id}
							control={<Radio size="small" />}
							label={
								<TypeWithNameLink
									href={`${process.env.PRODUCTION_URL}loading.html?callbackUrl=${encodeURIComponent(`/${router.locale}/users/${session_user.slug || session_user._id}`)}`}
									rel="noopener"
									target="_self"
									referrerPolicy="no-referrer"
									sx={{
										margin: router.locale === "ar-AE" ? "0rem 0.5rem 0rem 0rem" : "0rem 0rem 0rem 0rem",
									}}
								>
									<Heading sx={{ width: "100%", fontSize: "1rem" }}>{userName}</Heading>
									<TypeTypo>
										{router.locale === "ar-AE"
											? "الملف الشخصي للمستخدم"
											: router.locale === "kn-IN"
												? "ಬಳಕೆದಾರರ ಪ್ರೊಫೈಲ್"
												: "User "}
									</TypeTypo>
								</TypeWithNameLink>
							}
							sx={{ justifyContent: "space-between", width: "100%" }}
							labelPlacement="start"
						/>
						{/* <a
							href={`${process.env.PRODUCTION_URL}loading.html?callbackUrl=${encodeURIComponent(`/${router.locale}/users/${session_user.slug || session_user._id}`)}`}
							rel="noopener"
							target="_self"
							referrerPolicy="no-referrer"
							title="Open User Profile"
						>
							<OpenInNew
								sx={{
									fontSize: "1.5rem",
									color: theme.palette.primary.main,
								}}
							/>
						</a> */}
					</RadioWithLink>

					{businessProfiles.slice(0, 1).map((page: any, index: number) => (
						<RadioWithLink key={index}>
							{page.display_picture_url.exists ? (
								<ProfilePicture
									src={`/profile-picture/${page.display_picture_url.file_id}`}
									alt={page.page_title.toUpperCase()}
									referrerPolicy="no-referrer"
									loading="lazy"
								/>
							) : (
								<Initials
									title={page.page_title}
									profiledropdown={false}
									header={false}
									comment={false}
									postcard={false}
									businessprofile={false}
									selectProfile={true}
								/>
							)}
							<CustomFormControlLabel
								key={index}
								value={page.page_id}
								control={<Radio size="small" />}
								label={
									<TypeWithNameLink
										href={`${process.env.PRODUCTION_URL}loading.html?callbackUrl=${encodeURIComponent(`/${router.locale}/${page.page_type}s/${page.page_slug}`)}`}
										rel="noopener"
										target="_self"
										referrerPolicy="no-referrer"
										sx={{
											margin: router.locale === "ar-AE" ? "0rem 0.5rem 0rem 0rem" : "0rem 0rem 0rem 0rem",
										}}
									>
										<Heading sx={{ fontSize: "1rem" }}>{page.page_title}</Heading>
										<TypeTypo>
											{page.page_type === "agent"
												? router.locale === "ar-AE"
													? "وكلاء العقارات"
													: router.locale === "kn-IN"
														? "ಏಜೆಂಟ್"
														: "Agent"
												: page.page_type === "developer"
													? router.locale === "ar-AE"
														? "المطور"
														: router.locale === "kn-IN"
															? "ಡೆವಲಪರ್"
															: "Developer"
													: page.page_type === "professional"
														? router.locale === "ar-AE"
															? "مالك الأرض"
															: router.locale === "kn-IN"
																? "ಭೂಮಿಯ ಮಾಲಿಕ"
																: "Landowner"
														: page.page_type === "professional"
															? router.locale === "ar-AE"
																? "محترف الخدمة"
																: router.locale === "kn-IN"
																	? "ಪ್ರೊಫೆಶನಲ್"
																	: "Service Professional"
															: page.page_type.charAt(0).toUpperCase() + page.page_type.slice(1)}
										</TypeTypo>
									</TypeWithNameLink>
								}
								sx={{ justifyContent: "space-between", width: "100%" }}
								labelPlacement="start"
							/>
							{/* <a
								href={`${process.env.PRODUCTION_URL}loading.html?callbackUrl=${encodeURIComponent(`/${router.locale}/${page.page_type}s/${page.page_slug}`)}`}
								rel="noopener"
								target="_self"
								referrerPolicy="no-referrer"
								title="Open Business Profile"
							>
								<OpenInNew
									sx={{
										fontSize: "1.5rem",
										color: theme.palette.primary.main,
									}}
								/>
							</a> */}
						</RadioWithLink>
					))}
				</RadioGroup>
			</ContentContainer>
		</React.Fragment>
	);
};

export default UserContent;
