/*

? First, let's import the required components.

*/
import { styled } from "@mui/system";
import React from "react";

import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";
import { Autocomplete, CircularProgress, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
/*

* Google Maps Imports

*/
import { useLoadScript } from "@react-google-maps/api";
import { useRouter } from "next/router";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import StateInterface from "../../../../redux-magic/state-interface";
import { SearchFilterId } from "../../../../redux-magic/sub-interfaces/search-types";
import {
	setSearchAnimationDetailsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterSelectedOptionsThunk,
} from "../../../../redux-magic/thunks";

/*

* Interface for location

*/

interface Suggestion {
	description: string;
	matched_substrings: Array<{ length: number; offset: number }>;
	place_id: string;
	reference: string;
	structured_formatting: {
		main_text: string;
		main_text_matched_substrings: Array<{ length: number; offset: number }>;
		secondary_text: string;
	};
	terms: Array<{ offset: number; value: string }>;
	types: Array<string>;
}

interface GeolocationCoordinates {
	latitude: number;
	longitude: number;
	altitude: number | null;
	accuracy: number;
	altitudeAccuracy: number | null;
	heading: number | null;
	speed: number | null;
}
interface GeolocationPosition {
	coords: GeolocationCoordinates;
}
interface GMRGCPlusCode {
	compound_code: string;
	global_code: string;
}
interface GMRGCResultsAddressComponent {
	long_name: string;
	short_name: string;
	types: Array<string>;
}
interface GMRGCResultsGeometryLocation {
	lat: number;
	lng: number;
}
interface GMRGCResultsGeometryBoundsAndGeometryViewport {
	northeast: GMRGCResultsGeometryLocation;
	southwest: GMRGCResultsGeometryLocation;
}
interface GMRGCResultsGeometry {
	bounds: GMRGCResultsGeometryBoundsAndGeometryViewport;
	location: GMRGCResultsGeometryLocation;
	location_type: string;
}
interface GMRGCResults {
	address_components: Array<GMRGCResultsAddressComponent>;
	formatted_address: string;
	geometry: GMRGCResultsGeometry;
	viewport: GMRGCResultsGeometryBoundsAndGeometryViewport;
	place_id: string;
	types: Array<string>;
}
interface GoogleMapsReverseGeocodingApiResponse {
	plus_code: GMRGCPlusCode;
	results: Array<GMRGCResults>;
	status: string;
}

/*

& Next, let's create the component.

*/

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "0.688rem",
	margin: "0rem 0rem 0rem 0rem",
}));

const OptionContainer = styled("li")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "flex-start",
	width: "100%",
	// padding: "0.25rem 0.5rem",
}));

const OptionText = styled(Typography, {
	shouldForwardProp: (prop) => prop !== "isPrimary",
})<any>(({ theme, isPrimary }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	fontSize: "0.875rem",
	lineHeight: "1.3125rem",
	fontWeight: 400,
	color: isPrimary ? theme.palette.primary.main : theme.palette.text.primary,
})) as any;

const OptionSubtext = styled(Typography)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	fontSize: "0.875rem",
	lineHeight: "1.3125rem",
	fontWeight: 400,
	color: theme.palette.text.secondary,
})) as typeof Typography;

const librariesToLoad: ("places" | "drawing" | "geometry" | "localContext" | "visualization")[] = [
	"places",
	"drawing",
	"geometry",
];

const LocationAutoCompleteMobile = ({
	search_id,
	dispatch,
	search_filter_state,
}: {
	search_id: SearchFilterId;
	dispatch: Function;
	search_filter_state: StateInterface["search_filters_state"]["location"];
}) => {
	const [loading, setLoading] = React.useState(false);

	const theme = useTheme();
	const router = useRouter();

	const isMobile = useMediaQuery(theme.breakpoints.down("md"));
	/*

  & let's declare the states for the usePlacesAutocomplete hook

  */
	const {
		value,
		suggestions: { data },
		setValue,
		init,
	} = usePlacesAutocomplete({
		initOnMount: false,
		debounce: 500,
	});
	/*

  & let's declare the states for the useLoadScript hook

  */
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY_NO_REFERRER_RESTRICTIONS || "",
		libraries: librariesToLoad as any,
	});
	const [options, setOptions] = React.useState<string[]>([]);

	const getCurrentLocation = () => {
		setLoading(true);
		setValue("");
		/*
    & Next, let's check if the user has allowed us to use their location and update the corresponding state accordingly.
    */
		if (!navigator.geolocation) {
			return;
		} else {
			/*
      & Next, since it looks like the user has allowed us to use their location, let's get their current location. Let's begin by disabling the location autocomplete input.
      */
			// setAllowUserInputInLocationAutocomplete(true);
			navigator.geolocation.getCurrentPosition(
				async (position: GeolocationPosition) => {
					/*
          & Next, let's extract the latitude and longitude from the position object.
          */
					const { latitude, longitude }: { latitude: number; longitude: number } = position.coords;
					/*
          & Next, let's use Google Maps' Reverse Geocoding API to get the user's current address.
          */
					const googleMapsReverseGeocodingApiResponse: GoogleMapsReverseGeocodingApiResponse = await (
						await fetch(
							"https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
								latitude +
								"," +
								longitude +
								"&key=" +
								(process.env.GOOGLE_MAPS_API_KEY_NO_REFERRER_RESTRICTIONS || ""),
							{ cache: "no-store" },
						)
					).json();

					/*
          & Next, let's extract the user's current address from the API response object.
          */
					const userCurrentAddress: string | undefined =
						googleMapsReverseGeocodingApiResponse?.results[0]?.address_components?.find(
							(address_component: GMRGCResultsAddressComponent) =>
								address_component.types.includes("sublocality_level_1") ||
								address_component.types.includes("sublocality_level_2") ||
								address_component.types.includes("sublocality_level_3"),
						)?.long_name;
					const userCurrentAreas: Array<string> | undefined = googleMapsReverseGeocodingApiResponse?.results.map(
						(result) => {
							return result.address_components?.find(
								(address_component: GMRGCResultsAddressComponent) =>
									address_component.types.includes("sublocality_level_1") ||
									address_component.types.includes("postal_town") ||
									address_component.types.includes("locality"),
							)?.long_name as string;
						},
					);

					let userCurrentArea: string | undefined;

					let resultPos: number = 0;

					for (let i = 0; i < userCurrentAreas.length; i++) {
						if (userCurrentAreas[i] !== "Bengaluru") {
							userCurrentArea = userCurrentAreas[i];
							resultPos = i;
							break;
						}
					}

					/*
          & Next, let's update the corresponding state with the user's current address. Let's also set the userLocationAllowed state to true and the allowUserInputInLocationAutocomplete state to false.
          */
					const label = userCurrentAddress ? userCurrentAddress : userCurrentArea ? userCurrentArea : "";

					setValue(label);

					if (router.asPath.startsWith("/search")) {
						router
							.push(
								{
									pathname: "/search",
								},
								"/search",
								{ locale: router.locale },
							)
							.then(() => {
								dispatch(
									updateSearchFilterSelectedOptionsThunk({
										searchFilterId: search_id,
										newOptions: [
											{
												id: "location",
												title: "Location",
												value: {
													title: label,
													coordinates: [latitude, longitude],
												},
											},
										],
									}),
								);
								dispatch(
									updateSearchFilterAppliedThunk({
										searchFilterId: search_id,
										isApplied: true,
									}),
								);
								dispatch(
									setSearchAnimationDetailsThunk({
										StartAnimate: true,
										TriggerSearch: true,
										ResetSearch: true,
										PropertySubCategory: ["Budget", "Status", "Listed by", "Facing"],
										HomeAccordion: false,
										OpenLocationDrawer: false,
									}),
								);
							});
					} else {
						const urlPathToRedirect = encodeURIComponent(
							`${process.env.PRODUCTION_URL || ""}/search?search_type=properties&location=${label},${[longitude, latitude]}&transaction_type=rent&property_category=residential&property_type=Apartment&locale=${router.locale}`,
						);

						router.push(`/loading.html?callbackUrl=${urlPathToRedirect}`);
					}
					setLoading(false);
				},
				async (error) => {},
				{ timeout: 10000, enableHighAccuracy: true },
			);
		}
	};

	/*

  & let's define the function to handle the change in the location autocomplete

  */
	const onChangeLocationAutocomplete = async (event: React.SyntheticEvent<HTMLSelectElement>, tvalue: any) => {
		const item = data.find((res) => res.place_id === tvalue);
		if (item) {
			const temp = item.structured_formatting.main_text;

			if (Number.isNaN(+temp)) {
				setValue(temp);
			} else {
			}
		}
		const { lat, lng } = getLatLng((await getGeocode({ placeId: tvalue }))[0]);
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: [
					{
						id: "location",
						title: "Location",
						value: {
							title: item?.structured_formatting?.main_text || "",
							coordinates: [lng, lat],
						},
					},
				],
			}),
		);
		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: search_id,
				isApplied: true,
			}),
		);
		if (router.asPath.startsWith("/search")) {
			dispatch(
				setSearchAnimationDetailsThunk({
					StartAnimate: true,
					TriggerSearch: true,
					ResetSearch: true,
					PropertySubCategory: ["Budget", "Status", "Listed by", "Facing"],
					HomeAccordion: false,
					OpenLocationDrawer: false,
				}),
			);
		} else {
			const urlPathToRedirect = encodeURIComponent(
				`${process.env.PRODUCTION_URL || ""}/search?search_type=properties&location=${item?.structured_formatting?.main_text},${[lng, lat]}&transaction_type=rent&property_category=residential&property_type=Apartment&locale=${router.locale}`,
			);
			router.push(`/loading.html?callbackUrl=${urlPathToRedirect}`);
		}
	};

	/*

  & let's use the useEffect hook to set the value of the location autocomplete to the default location and initialize the autocomplete

  */

	React.useEffect(() => {
		if (isLoaded) {
			init();
		}
	}, [isLoaded, init]);
	React.useEffect(() => {
		/*
		 * Filter the suggestions to only show the ones in Karnataka, India.
		 * If no valid suggestions exist, add a default message.
		 */

		let filteredOptions = data
			.filter((suggestion: Suggestion) =>
				suggestion?.structured_formatting?.secondary_text?.includes("Karnataka, India"),
			)
			.map((suggestion: Suggestion) => suggestion?.place_id)
			.filter((option: string, index: number, self: string[]) => option !== "" && self.indexOf(option) === index);

		setOptions(filteredOptions);
	}, [data]);

	React.useEffect(() => {
		if (isMobile && search_filter_state?.selected_options.length !== 0) {
			const selectedOption = search_filter_state?.selected_options[0]?.value;
			if (typeof selectedOption === "object" && "title" in selectedOption) {
				setValue(selectedOption.title);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search_filter_state, isMobile]);

	const localLang = {
		useCurrentLocation:
			router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಪ್ರಸ್ತುತ ಸ್ಥಳವನ್ನು ಬಳಸಿ." : "Use current location",
		useGPSorIP:
			router.locale === "ar-AE"
				? ""
				: router.locale === "kn-IN"
					? "ಜಿಪಿಎಸ್ ಅಥವಾ ಐಪಿ ವಿಳಾಸವನ್ನು ಬಳಸಿ"
					: "Please alow location",
		inTheMiddleOfNowhere:
			router.locale === "ar-AE"
				? "متاح حاليًا فقط في كارناتاكا"
				: router.locale === "kn-IN"
					? "ಪ್ರಸ್ತುತ ಕೇವಲ ಕರ್ನಾಟಕದಲ್ಲಿ ಲಭ್ಯವಿದೆ"
					: "Currently available only in Karnataka",
		enterLocation: router.locale === "ar-AE" ? "" : router.locale === "kn-IN" ? "ಸ್ಥಳವನ್ನು ನಮೂದಿಸಿ" : "Enter Location",
	};

	return (
		<React.Fragment>
			<Container
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "flex-start",
					width: "100%",
				}}
			>
				<div
					style={{
						width: "100%",
						padding: "0rem 0.05rem 0rem 0.05rem",
					}}
				>
					<Autocomplete
						disablePortal
						freeSolo={true}
						id="combo-box-demo"
						filterOptions={() => options}
						options={options}
						isOptionEqualToValue={(option: string, value: string) => {
							const item = data.find((res: any) => res.structured_formatting.main_text === value)?.place_id;
							return !item ? false : option === item;
						}}
						// noOptionsText={localLang.inTheMiddleOfNowhere}
						value={value}
						renderOption={(props, option) => {
							const las: any = data.find((suggestion: any) => suggestion.place_id === option);
							const optionText: string | undefined =
								option === localLang.useCurrentLocation
									? localLang.useCurrentLocation
									: las?.structured_formatting?.main_text;
							const optionSubtext: string | undefined =
								option === localLang.useCurrentLocation
									? localLang.useCurrentLocation
									: las
										? las?.structured_formatting?.secondary_text
										: undefined;

							const { key, ...restProps } = props;

							return (
								<OptionContainer
									key={key}
									{...restProps}
								>
									<OptionText
										variant="body1"
										component="span"
										isPrimary={option === localLang.useCurrentLocation}
									>
										{optionText}
									</OptionText>
									<OptionSubtext
										variant="body2"
										component="span"
									>
										{optionSubtext
											? optionSubtext === localLang.useCurrentLocation
												? localLang.useGPSorIP
												: optionSubtext
											: localLang.inTheMiddleOfNowhere}
									</OptionSubtext>
								</OptionContainer>
							);
						}}
						onChange={onChangeLocationAutocomplete}
						sx={{
							width: "100%",
							borderRadius: "0.5rem",
							boxShadow: " 0px 1px 3px 0px rgba(0, 0, 0, 0.12);",
							"& fieldset": { borderRadius: "0.75rem", border: "none" },
						}}
						renderInput={(params) => (
							<TextField
								variant="outlined"
								{...params}
								onChange={(e) => {
									setValue(e.target.value);
								}}
								placeholder={loading && value === "" ? "Fetching location..." : localLang.enterLocation}
								slotProps={{
									input: {
										sx: {
											borderRadius: "0.75rem",

											"&::placeholder": {
												fontSize: "0.875rem",
											},
										},
										...params.InputProps,
									},
								}}
							/>
						)}
					/>
				</div>
				<div
					style={{
						display: "flex",
						padding: "0.25rem 0rem",
						gap: "0.5rem",
						justifyContent: "space-between",
					}}
					onClick={() => {
						getCurrentLocation();
					}}
				>
					{loading && value === "" ? (
						<CircularProgress size={19} />
					) : (
						<NearMeOutlinedIcon
							sx={{
								color: "#FC8019",
								fontSize: "1.25rem",
							}}
						/>
					)}
					<Typography
						variant="subtitle2"
						sx={{
							color: "#FC8019",
						}}
					>
						{localLang.useCurrentLocation}
					</Typography>
				</div>
			</Container>
		</React.Fragment>
	);
};

export default LocationAutoCompleteMobile;
