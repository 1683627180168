/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Type Imports */

import { ObjectId } from "mongodb";

/* Component Imports */

import {
	Backdrop,
	Card,
	CircularProgress,
	Collapse,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	InputAdornment,
	// SvgIcon,
	Tab,
	Tabs,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import crypto from "crypto";
import { useRouter } from "next/router";
import BeegruButton from "../common-components/buttons/BeegruButton";
// import BeegruPoints from "../../public/images/icons/beegru-points/beegru-points-logo.svg";

/* Icon Imports */

import {
	Add,
	CheckCircleOutlineOutlined,
	Close,
	CurrencyRupee,
	EmojiObjectsOutlined,
	ErrorOutlineOutlined,
	ExpandLessOutlined,
	ExpandMoreOutlined,
	PaymentsOutlined,
	Remove,
	SwapVertOutlined,
} from "@mui/icons-material";
import NumberToCurrencyPipe from "../../lib/NumberToCurrencyPipe";
import StateInterface from "../../redux-magic/state-interface";
import { setBottomBuyBeegruPointsThunk, updateBeegrupointsAfterPurchaseThunk } from "../../redux-magic/thunks";

/* Styled Components */

const CustomDialog = styled(Dialog)(({ theme }) => ({
	padding: "1rem 1rem 1rem 1rem",
}));

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	fontSize: "1rem",
	padding: "0.5rem 1rem 0rem 1.5rem",
	background: theme.palette.background.paper,
}));

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	gap: "0.5rem",
	padding: "1rem 1rem 1rem 1rem",
	background: theme.palette.background.default,
}));

const CustomDialogActions = styled(DialogActions)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "space-between",
	width: "100%",
	gap: "0.5rem",
	padding: "1rem",
	background: theme.palette.background.paper,
}));

const CustomTab = styled(Tab)(({ theme }) => ({
	flexGrow: 1,
	textTransform: "capitalize",
}));

const TabTitle = styled(Typography)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	fontWeight: 500,
	fontSize: "0.875rem",
}));

const CardContainerDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	gap: "1rem",
	width: "100%",
}));

const CardSize = styled(Card)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	boxShadow: " 0px 4px 18px 0px #0000000D",
	borderRadius: "1rem",
	width: "100%",
	padding: "0.75rem 1rem 0.75rem 1rem",
	background: theme.palette.background.paper,
	gap: "0.5rem",
}));

const PriceInfoDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "27%",
}));

const CostPriceDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0rem",
	width: "30%",
}));

const ActionDiv = styled("div")(({ theme }) => ({
	display: "flex",
	justifyContent: "space-evenly",
	alignItems: "center",
	border: "1px solid var(--Other-New-Divider, rgba(0, 0, 0, 0.12))",
	borderRadius: "0.5rem",
	width: "4.75rem",
	cursor: "pointer",
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
	borderRadius: "0",
}));

const PackText = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
	fontSize: "0.835rem",
	lineHeight: "1.374rem",
}));

const DiscountText = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
	fontSize: "0.625rem",
	lineHeight: "0.875rem",
	color: theme.palette.success.main,
}));

const PriceText = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
	fontSize: "0.835rem",
	lineHeight: "1rem",
}));

const OriginalPriceText = styled(Typography)(({ theme }) => ({
	fontWeight: 400,
	fontSize: "0.75rem",
	lineHeight: "1rem",
	color: theme.palette.text.secondary,
	textDecoration: "line-through",
}));

const ConversionRate = styled(Typography)(({ theme }) => ({
	fontWeight: 400,
	fontSize: "0.875rem",
	lineHeight: "1rem",
	color: theme.palette.text.secondary,
}));

const BuyIndividualDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	gap: "0.5rem",
	width: "100%",
	padding: "2.5rem 0rem 2rem 0rem",
	margin: "0rem 0rem 0rem 0rem",
	borderRadius: "1rem",
	background: theme.palette.background.paper,
}));

const TipsDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	gap: "0.5rem",
	width: "100%",
	padding: "2rem 0rem 1rem 0rem",
}));

const IconTextDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	gap: "0.25rem",
}));

const TipText = styled(Typography)(({ theme }) => ({
	fontWeight: 400,
	fontSize: "0.75rem",
	lineHeight: "1.25rem",
	color: "#212121",
	marginLeft: "0.5rem",
}));

const TextfieldDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	gap: "0.5rem",
	width: "50%",
}));

const PointDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.35rem",
}));

const TextDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "flex-start",
	gap: "0.2rem",
}));

const TopayDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
}));

const SubTotalText = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
	fontSize: "0.875rem",
	lineHeight: "1.25rem",
}));

const DetailText = styled(Typography)(({ theme }) => ({
	fontWeight: 500,
	fontSize: "0.75rem",
	lineHeight: "1rem",
	color: "#666666",
}));

const Container = styled("div")(({ theme }) => ({
	padding: "1rem 1rem 1rem 1rem",
}));

const TopayTextContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
}));

const InvoiceDiv = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	padding: "0.25rem 0rem 0rem 0rem",
}));

const InvoiceText = styled(Typography)(({ theme }) => ({
	fontWeight: 400,
	fontSize: "0.875rem",
	color: "#666666",
	lineHeight: "1.25rem",
}));

const SuccessContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	gap: "2rem",
	height: "100%",
	width: "100%",
	padding: "6rem 2rem 4rem 2rem",
	background: theme.palette.background.default,
}));

const SuccessSection = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	gap: "0.5rem",
	width: "100%",
}));

const PointsCard = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	gap: "1rem",
	width: "100%",
	background: theme.palette.background.paper,
	padding: "1.5rem 1.5rem 1.5rem 1.5rem",
	borderRadius: "1rem",
}));

const TitleRow = styled("div")(() => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
}));

const PointsRow = styled("div")(() => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "auto",
	gap: "0.25rem",
}));

function TabPanel(props: any) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			style={{ width: "100%" }}
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && children}
		</div>
	);
}

function a11yProps(index: any) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const BuyBeegruPointsModal = ({
	user_id,
	name,
	email,
	phone,
	open,
	onClose,
	tabvalue,
	bottom_buy_subscription,
	dispatch,
	available_beegru_points,
}: {
	user_id: string | ObjectId;
	name: string;
	email: string;
	phone: string;
	open: boolean;
	onClose: () => void;
	tabvalue?: number;
	bottom_buy_subscription: StateInterface["bottom_subscribe_buy_beegru_points"];
	dispatch: any;
	available_beegru_points: number;
}) => {
	const theme = useTheme();

	const router = React.useRef(useRouter()).current;

	/* Get the asPath without the query parameters */

	const routerPathWithoutQuery = router.asPath.split("?")[0];

	/*

	& Next, let's declare window dom elemet

	*/

	const Window: any = window;

	/*

	& Next, let's declare a local state to hold the tab value and drawer value.

	*/

	const [bulktabvalue, setBulkTabValue] = React.useState(0);

	const handleChangeBulk = (event: React.SyntheticEvent, newValue: number) => {
		setBulkTabValue(newValue);
	};

	const [drawerOpen, setDrawerOpen] = React.useState(false);

	const [drawerOpenBulk, setDrawerOpenBulk] = React.useState(false);

	const handleDrawerClick = () => {
		setDrawerOpen(!drawerOpen);
	};

	const handleDrawerBulkClick = () => {
		setDrawerOpenBulk(!drawerOpenBulk);
	};

	/*

	& Next, let's declare conversion rate.

	*/

	const conversion_rate: number = 60;

	/*

		& Next, let's declare a local state to hold the razorpay success.

	*/

	const [transactionSuccess, setTransactionSuccess] = React.useState(false);

	const [paymentStatus, setPaymentStatus] = React.useState("");

	/*

	& Next, let's declare a local state to hold the payment loading state.

	*/

	const [paymentLoading, setPaymentLoading] = React.useState<boolean>(false);

	const [paymentVerifying, setPaymentVerifying] = React.useState<boolean>(false);

	const [orderId, setOrderId] = React.useState("");

	/*

	& Next, let's define the pack of beegru points and thier prices.

	*/

	const cards = [
		{
			points: 10,
			discount: "10%",
			price: 600,
			discounted_price: 540,
		},
		{
			points: 100,
			discount: "15%",
			price: 6000,
			discounted_price: 5100,
		},
		{
			points: 500,
			discount: "20%",
			price: 30000,
			discounted_price: 24000,
		},
		{
			points: 1000,
			discount: "25%",
			price: 60000,
			discounted_price: 45000,
		},
	];

	const [quantity, setQuantity] = React.useState(Array(cards.length).fill(0));

	const [totalPrice, setTotalPrice] = React.useState(0);

	const [beegruPoints, setBeegruPoints] = React.useState<number | string>(1);

	const [indianRupees, setIndianRupees] = React.useState<number | string>(60);

	const subTotal = cards.reduce((total, card, index) => total + card.discounted_price * quantity[index], 0);

	const GST =
		bulktabvalue === 0
			? Number(((subTotal * 0.18) / 1.18).toFixed(2))
			: Number(((Number(indianRupees) * 0.18) / 1.18).toFixed(2));

	const grandTotal = bulktabvalue === 0 ? Number(Number(subTotal).toFixed(2)) : Number(Number(indianRupees).toFixed(2));

	// const grandTotal = subTotal + GST;

	const amount = bulktabvalue === 0 ? grandTotal.toFixed(2) : Number(indianRupees) + Number(indianRupees) * 0.18;

	const [isBeegruPointsActive, setIsBeegruPointsActive] = React.useState(true);

	const handleBeegruPointsChange = (event: any) => {
		let points = event.target.value.replace(/[^.0-9]/g, "");

		// Ensure only one decimal point is allowed
		const parts = points.split(".");
		if (parts.length > 2) {
			points = parts[0] + "." + parts.slice(1).join("");
		}

		// Limit to 2 decimal places
		if (parts[1] && parts[1].length > 2) {
			points = `${parts[0]}.${parts[1].slice(0, 2)}`;
		}

		setBeegruPoints(points);
		const rupees = (Number(points) * conversion_rate).toFixed(2);
		setIndianRupees(rupees.endsWith(".00") ? Number(rupees) : rupees);
	};

	const handleIndianRupeesChange = (event: any) => {
		let rupees = event.target.value.replace(/[^.0-9]/g, "");

		// Ensure only one decimal point is allowed
		const parts = rupees.split(".");
		if (parts.length > 2) {
			rupees = parts[0] + "." + parts.slice(1).join("");
		}

		// Limit to 2 decimal places
		if (parts[1] && parts[1].length > 2) {
			rupees = `${parts[0]}.${parts[1].slice(0, 2)}`;
		}

		setIndianRupees(rupees);
		const points = (Number(rupees) / conversion_rate).toFixed(2);
		setBeegruPoints(points.endsWith(".00") ? Number(points).toString() : points);
	};

	const handleSwap = () => {
		setIsBeegruPointsActive(!isBeegruPointsActive);
	};

	const handleIncrement = (index: number) => {
		setQuantity((prevQuantity) => {
			const newQuantity = [...prevQuantity];
			newQuantity[index]++;
			return newQuantity;
		});

		setTotalPrice((prevTotalPrice) => prevTotalPrice + cards[index].price);
	};

	const handleDecrement = (index: number) => {
		setQuantity((prevQuantity) => {
			const newQuantity = [...prevQuantity];
			if (newQuantity[index] > 0) {
				newQuantity[index]--;
			}
			return newQuantity;
		});

		setTotalPrice((prevTotalPrice) => prevTotalPrice - cards[index].price);
	};

	React.useEffect(() => {
		if (bottom_buy_subscription)
			dispatch(
				setBottomBuyBeegruPointsThunk({
					index: bottom_buy_subscription.index,
					packof10: { quantity: quantity[0], total: quantity[0] * 540 },
					packof100: { quantity: quantity[1], total: quantity[1] * 5100 },
					packof500: { quantity: quantity[2], total: quantity[2] * 24000 },
					packof1000: { quantity: quantity[3], total: quantity[3] * 45000 },
					subtotal: bulktabvalue === 0 ? Number(Number(subTotal).toFixed(2)) : Number(Number(indianRupees).toFixed(2)),
					gst: GST,
					grandtotal:
						bulktabvalue === 0 ? Number(Number(subTotal).toFixed(2)) : Number(Number(indianRupees).toFixed(2)),
				}),
			);
		/* eslint-disable-next-line react-hooks/exhaustive-deps*/
	}, [GST, grandTotal, quantity, subTotal]);

	/*

  & Next, let's define a function to handle the beegru points purchase.

  */

	const processPayment = async () => {
		// event.preventDefault();

		/*

		& Let's begin by blocking the UI.

		*/

		setPaymentLoading(true);

		/*

		& Let's retrieve all the necessary values.

		*/

		const receipt = crypto.randomBytes(10).toString("hex");

		/*

		& Next, let's create a new Razorpay Order.

		*/

		const razorpay_order = await (
			await fetch("/api/account-settings/create-order", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					user_id: user_id,
					beegru_points_amount:
						bulktabvalue === 0
							? quantity[0] * 10 + quantity[1] * 100 + quantity[2] * 500 + quantity[3] * 1000
							: beegruPoints,
					fiat_amount:
						bulktabvalue === 0 ? Number(Number(subTotal).toFixed(2)) : Number(Number(indianRupees).toFixed(2)),
					conversion_rate: conversion_rate,
					currency: "INR",
					receipt: receipt,
					pack_of_10: {
						pack_price: 540,
						quantity: quantity[0],
						total_price: quantity[0] * 540,
						total_beegru_points: quantity[0] * 10,
					},
					pack_of_100: {
						pack_price: 5100,
						quantity: quantity[1],
						total_price: quantity[1] * 5100,
						total_beegru_points: quantity[1] * 100,
					},
					pack_of_500: {
						pack_price: 24000,
						quantity: quantity[2],
						total_price: quantity[2] * 24000,
						total_beegru_points: quantity[2] * 500,
					},
					pack_of_1000: {
						pack_price: 45000,
						quantity: quantity[3],
						total_price: quantity[3] * 45000,
						total_beegru_points: quantity[3] * 1000,
					},
					// gst: bulktabvalue === 0 ? GST.toFixed(2) : (Number(indianRupees) * 0.18).toFixed(2),
					gst: GST,
					sub_total: grandTotal - GST,
					buy_pack: bulktabvalue === 0 ? true : false,
					redirect_url: routerPathWithoutQuery,
				}),
				cache: "no-store",
			})
		).json();

		setOrderId(razorpay_order.payload._id);

		/*

		& Let's dynamically import the Razorpay client-side library.

		*/

		const options: any = {
			// key: process.env.RAZORPAY_KEY_ID_TEST,
			key: process.env.RAZORPAY_KEY_ID_PROD,
			amount: razorpay_order.payload.transaction.fiat_amount,
			currency: "INR",
			name: "Beegru",
			description:
				"Payment for the purchase of " + razorpay_order.payload.transaction.beegru_points_amount + " Beegru Points",
			order_id: razorpay_order.payload.transaction.order_id,
			handler: async function (response: any) {
				setPaymentVerifying(true);

				await fetch(
					"/api/account-settings/verify-payment?orderId=" +
						razorpay_order.payload.transaction.order_id +
						"&tab=" +
						tabvalue,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							razorpay_payment_id: response.razorpay_payment_id,
							razorpay_order_id: response.razorpay_order_id,
							razorpay_signature: response.razorpay_signature,
						}),
						cache: "no-store",
					},
				).then((res) => {
					res.json();

					/*

					& Let's unblock the UI.

					*/

					if (res.status === 200) {
						/* Payment Successful */

						dispatch(updateBeegrupointsAfterPurchaseThunk(available_beegru_points));
						setPaymentVerifying(false);
						setPaymentLoading(false);
						setPaymentStatus("success");
						setTransactionSuccess(true);
					} else {
						/* Payment Failed */

						setPaymentVerifying(false);
						setPaymentStatus("failed");
						setTransactionSuccess(true);
					}
				});
			},
			modal: {
				ondismiss: function () {
					setPaymentVerifying(false);
					setPaymentLoading(false);
				},
			},
			prefill: {
				name: name ? name : "",
				email: email ? email : "",
				contact: phone ? phone : "",
			},
			notes: {
				order_id: razorpay_order.payload.transaction.order_id,
			},
			theme: {
				color: theme.palette.primary.main,
			},
			remember_customer: true,
			retry: {
				enabled: true,
				max_count: 3,
			},
			redirect: false,
		};

		/*

		& Now, let's create a new Razorpay client-side instance.

		*/

		const razorpay = new Window.Razorpay(options);

		razorpay.open();

		/* Payment Failed */

		razorpay.on("payment.failed", (error: any) => {
			console.error("Payment Failed", error);
			setPaymentVerifying(false);
			setPaymentStatus("failed");
			setTransactionSuccess(true);
		});
	};

	const localLang = {
		dialogText: router.locale === "kn-IN" ? "ಬೀಗ್ರು ಪಾಯಿಂಟ್‌ಗಳನ್ನು ಖರೀದಿಸಿ" : "Buy Beegru Points",
		tabBuyPacks: router.locale === "kn-IN" ? "ಪ್ಯಾಕ್‌ಗಳನ್ನು ಖರೀದಿಸಿ" : "Buy packs",
		tabBuyPoints: router.locale === "kn-IN" ? "ಅಂಕಗಳನ್ನು ಖರೀದಿಸಿ" : "Buy points",
		packstext:
			router.locale === "kn-IN"
				? "ಬೀಗ್ರು ಪಾಯಿಂಟ್‌ಗಳನ್ನು ಎಲ್ಲಿ ಬಳಸಬೇಕು ಎಂಬುದರ ಕುರಿತು ಸಲಹೆಗಳು"
				: "Tips on where to use Beegru Points",
		tipstext1:
			router.locale === "kn-IN"
				? "ಪ್ರೀಮಿಯಂ ಚಂದಾದಾರಿಕೆಯನ್ನು ಖರೀದಿಸಲು ಅಂಕಗಳನ್ನು ಬಳಸಿ"
				: "Use Beegru Points to get premium subscription for your profile",
		tipstext2:
			router.locale === "kn-IN"
				? "ಬೂಸ್ಟ್‌ನಲ್ಲಿ ಬೀಗ್ರು ಪಾಯಿಂಟ್‌ಗಳನ್ನು ಬಳಸುವ ಮೂಲಕ ಆಸ್ತಿ ಗೋಚರತೆಯನ್ನು ಹೆಚ್ಚಿಸಿ"
				: "Increase visibility by using Beegru Points to boost your property",
		labelText1: router.locale === "kn-IN" ? "ಬೀಗ್ರು ಪಾಯಿಂಟ್‌ಗಳು" : "Beegru Points",
		labelText2: router.locale === "kn-IN" ? "ಭಾರತೀಯ ರೂಪಾಯಿಗಳು" : "Indian Rupees",
		conversionText: router.locale === "kn-IN" ? "ಪರಿವರ್ತನೆ ದರ" : "Conversion rate",
		swipeableToPayPointsText:
			router.locale === "kn-IN"
				? `₹${NumberToCurrencyPipe(Number(indianRupees).toFixed(2))} ಪಾವತಿಸಲು`
				: `To Pay ₹${NumberToCurrencyPipe(Number(indianRupees).toFixed(2))}`,
		swipeableToPayPacksText:
			router.locale === "kn-IN"
				? `₹${NumberToCurrencyPipe(subTotal.toFixed(2))} ಪಾವತಿಸಲು`
				: `To Pay ₹${NumberToCurrencyPipe(subTotal.toFixed(2))}`,
		inclTaxText:
			router.locale === "kn-IN" ? "ಎಲ್ಲಾ ತೆರಿಗೆಗಳು ಮತ್ತು ಶುಲ್ಕಗಳನ್ನು ಒಳಗೊಳ್ಳುತ್ತದೆ" : "Incl. all taxes & charges",
		beegruPoints: router.locale === "kn-IN" ? "ಒಟ್ಟು ಬೀಗ್ರು ಪಾಯಿಂಟ್‌ಗಳು" : "Beegru Points",
		subTotalText: router.locale === "kn-IN" ? "ಉಪ ಮೊತ್ತ" : "Sub total",
		gstText: router.locale === "kn-IN" ? "GST ಮತ್ತು ಇತರ ಶುಲ್ಕಗಳು" : "GST and other charges",
		grandTotal: router.locale === "kn-IN" ? "ಒಟ್ಟು ಮೊತ್ತ" : "Grand total",
		toPayText: router.locale === "kn-IN" ? "ಪಾವತಿಸಲು" : "To Pay",
		viewDetailsText: router.locale === "kn-IN" ? "ಪಾವತಿಸಲು" : "TO PAY",
		payNowText: router.locale === "kn-IN" ? "ಈಗ ಪಾವತಿಸಿ" : "Pay now",
		pack_of_10: router.locale === "kn-IN" ? "10 ಪ್ಯಾಕ್" : "Pack of 10",
		pack_of_100: router.locale === "kn-IN" ? "100 ಪ್ಯಾಕ್" : "Pack of 100",
		pack_of_500: router.locale === "kn-IN" ? "500 ಪ್ಯಾಕ್" : "Pack of 500",
		pack_of_1000: router.locale === "kn-IN" ? "1000 ಪ್ಯಾಕ್" : "Pack of 1000",
		discount: router.locale === "kn-IN" ? "ರಿಯಾಯಿತಿ" : "Discount",
		save_upto_25: router.locale === "kn-IN" ? "ಉಪಯೋಗಿಸಿ ಮತ್ತು 25% ಉಳಿಸಿ" : "Save upto 25%",
		saved: router.locale === "kn-IN" ? " ಉಳಿಸಿ" : " saved!",
		add: router.locale === "kn-IN" ? "ಸೇರಿಸಿ" : "Add",
		verifying_payment: router.locale === "kn-IN" ? "ಪಾವತಿಸಲು ಪರಿಶೀಲಿಸುತ್ತಿದ್ದೇನೆ..." : "Verifying your payment...",
		do_not_refresh:
			router.locale === "kn-IN" ? "ದಯವಿಟ್ಟು ಪುನಃ ಲೋಡ್ ಮಾಡಬೇಡಿ" : "Please do not refresh or close this page",
		beegru_points_added_successfully:
			router.locale === "kn-IN" ? "ಬೀಗ್ರು ಪಾಯಿಂಟ್‌ಗಳನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸೇರಿಸಲಾಗಿದೆ" : "Beegru Points added successfully!",
		transaction_id: router.locale === "kn-IN" ? "ಲಾಟ್ ನಂಬರ್: " : "Transaction ID: ",
		beegru_points_added: router.locale === "kn-IN" ? "ಬೀಗ್ರು ಪಾಯಿಂಟ್‌ಗಳನ್ನು ಸೇರಿಸಿ" : "Beegru Points added",
		total_beegru_points: router.locale === "kn-IN" ? "ಒಟ್ಟು ಬೀಗ್ರು ಪಾಯಿಂಟ್‌ಗಳು" : "Total Beegru Points",
		view_transactions:
			router.locale === "kn-IN"
				? "ನಿಮ್ಮ ಪಾವತಿಗಳನ್ನು ನೋಡಲು ಸೆಟ್ಟಿಂಗ್ಗಳ ವಿಭಾಗದಲ್ಲಿ ನೋಡಬಹುದು"
				: "You can view all your transactions under Settings > Payments",
		done: router.locale === "kn-IN" ? "ಮುಗಿಸಿ" : "Done",
		payment_failed: router.locale === "kn-IN" ? "ಪಾವತಿ ವಿಫಲವಾಯಿತು" : "Payment failed!",
		try_again: router.locale === "kn-IN" ? "ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ" : "Try again",
		cancel: router.locale === "kn-IN" ? "ರದ್ದು ಮಾಡಿ" : "Cancel",
	};

	return (
		<React.Fragment>
			{/* Payment verification backdrop */}

			<Backdrop
				sx={{
					display: "flex",
					flexDirection: "column",
					background: "white",
					// zIndex: theme.zIndex.drawer + 1,
					zIndex: 99999999,
				}}
				open={paymentVerifying}
			>
				<CircularProgress sx={{ margin: "0rem 0rem 1rem 0rem" }} />
				<Typography
					variant="body1"
					fontWeight="500"
				>
					{localLang.verifying_payment}
				</Typography>
				<Typography variant="body2">{localLang.do_not_refresh}</Typography>
			</Backdrop>

			{/*  Dialog */}

			<CustomDialog
				open={open}
				onClose={onClose}
				maxWidth="xs"
				fullWidth={true}
				// disableScrollLock={true}
			>
				{transactionSuccess ? (
					/* Post Transaction Screen */

					<SuccessContainer>
						<SuccessSection sx={{ gap: "2rem" }}>
							<SuccessSection>
								{paymentStatus === "success" ? (
									<CheckCircleOutlineOutlined
										sx={{
											color: theme.palette.success.main,
											fontSize: "10rem",
										}}
									/>
								) : (
									<ErrorOutlineOutlined
										sx={{
											color: theme.palette.error.main,
											fontSize: "10rem",
										}}
									/>
								)}

								<Typography
									variant="h6"
									textAlign="center"
								>
									{paymentStatus === "success" ? localLang.beegru_points_added_successfully : localLang.payment_failed}
								</Typography>
								<Typography
									variant="body2"
									color="textSecondary"
									textAlign="center"
								>
									{localLang.transaction_id + orderId}
								</Typography>
							</SuccessSection>

							{paymentStatus === "success" ? (
								<PointsCard>
									<TitleRow>
										<Typography
											variant="body2"
											fontWeight={500}
										>
											{localLang.beegru_points_added}
										</Typography>
										<PointsRow>
											<img
												src="/images/icons/beegru-points/beegru-points-logo.svg"
												alt="beegru-points"
												style={{
													height: 14,
													width: 14,
												}}
											/>
											{/* <SvgIcon
											component={BeegruPoints}
											inheritViewBox={false}
											viewBox="0 0 24 24"
											width={24}
											height={24}
											sx={{
												width: "0.875rem",
												height: "1.25rem",
											}}
										/> */}
											<Typography
												variant="body2"
												fontWeight={500}
											>
												{bulktabvalue === 0
													? quantity[0] * 10 + quantity[1] * 100 + quantity[2] * 500 + quantity[3] * 1000
													: beegruPoints}
											</Typography>
										</PointsRow>
									</TitleRow>

									<TitleRow>
										<Typography
											variant="body2"
											fontWeight={500}
										>
											{localLang.total_beegru_points}
										</Typography>
										<PointsRow>
											<img
												src="/images/icons/beegru-points/beegru-points-logo.svg"
												alt="beegru-points"
												style={{
													height: 14,
													width: 14,
												}}
											/>
											{/* <SvgIcon
											component={BeegruPoints}
											inheritViewBox={false}
											viewBox="0 0 24 24"
											width={24}
											height={24}
											sx={{
												width: "0.875rem",
												height: "1.25rem",
											}}
										/> */}
											<Typography
												variant="body2"
												fontWeight={500}
											>
												{available_beegru_points}
											</Typography>
										</PointsRow>
									</TitleRow>
								</PointsCard>
							) : null}
						</SuccessSection>

						<SuccessSection>
							<Typography
								variant="body2"
								textAlign="center"
								color="textSecondary"
								sx={{
									margin: "0rem 0rem 1rem 0rem",
								}}
							>
								{localLang.view_transactions}
							</Typography>

							{paymentStatus === "failed" ? (
								<BeegruButton
									variant="contained"
									name="try_again"
									type="button"
									fullWidth
									onClick={() => {
										setTransactionSuccess(false);
										setPaymentLoading(false);
									}}
								>
									{localLang.try_again}
								</BeegruButton>
							) : null}

							<BeegruButton
								variant="outlined"
								name="done"
								type="button"
								fullWidth
								onClick={() => {
									onClose();
									setTransactionSuccess(false);
									setBeegruPoints(1);
									setIndianRupees(60);
								}}
							>
								{paymentStatus === "success" ? localLang.done : localLang.cancel}
							</BeegruButton>
						</SuccessSection>
					</SuccessContainer>
				) : (
					/* Buy Beegru Points Modal */
					<>
						<CustomDialogTitle>
							{localLang.dialogText}

							<IconButton
								onClick={() => {
									onClose();
									setQuantity(Array(cards.length).fill(0));
									setPaymentLoading(false);
								}}
								disabled={paymentLoading}
							>
								<Close />
							</IconButton>
						</CustomDialogTitle>

						<Tabs
							value={bulktabvalue}
							onChange={handleChangeBulk}
							variant="fullWidth"
							aria-label="full width points tabs"
							sx={{
								background: theme.palette.background.paper,
								borderRadius: "0rem 0rem 0rem 0rem",
								margin: "0",
								padding: "0",
								width: "100%",
							}}
						>
							<CustomTab
								label={
									<TabTitle>
										<Typography
											variant="body2"
											sx={{
												fontSize: "0.875rem",
												fontWeight: 500,
											}}
										>
											{localLang.tabBuyPacks}
										</Typography>
										<Typography
											variant="caption"
											color="success"
											sx={{
												fontSize: "0.675rem",
												fontWeight: 500,
											}}
										>
											{localLang.save_upto_25}
										</Typography>
									</TabTitle>
								}
								{...a11yProps(0)}
							/>
							<CustomTab
								label={
									<TabTitle>
										<Typography
											variant="body2"
											sx={{
												fontSize: "0.875rem",
												fontWeight: 500,
											}}
										>
											{localLang.tabBuyPoints}
										</Typography>
										<Typography
											variant="caption"
											color="textDisabled"
											sx={{
												fontSize: "0.675rem",
												fontWeight: 500,
											}}
										>
											(INR)
										</Typography>
									</TabTitle>
								}
								{...a11yProps(1)}
							/>
						</Tabs>

						<CustomDialogContent>
							{/* Buy Packs */}

							<TabPanel
								value={bulktabvalue}
								index={0}
							>
								<CardContainerDiv>
									{cards.map((card, index) => (
										<CardSize key={index}>
											<PriceInfoDiv>
												<PackText variant="body2">
													{router.locale === "kn-IN" ? `${card.points} ರ ಪ್ಯಾಕ್` : `Pack of ${card.points}`}
												</PackText>
												<DiscountText variant="body2">
													{router.locale === "kn-IN" ? `${card.discount} ರಿಯಾಯಿತಿ` : `${card.discount} discount`}
												</DiscountText>
											</PriceInfoDiv>

											<CostPriceDiv>
												<OriginalPriceText variant="body2"> {"₹" + NumberToCurrencyPipe(card.price)}</OriginalPriceText>
												<PriceText variant="body2"> {"₹" + NumberToCurrencyPipe(card.discounted_price)}</PriceText>
											</CostPriceDiv>

											{quantity[index] === 0 ? (
												<ActionDiv onClick={() => handleIncrement(index)}>
													<PackText
														variant="h6"
														sx={{
															color: theme.palette.success.main,
															padding: "0.25rem 0rem",
															fontWeight: 400,
															fontSize: "0.75rem",
														}}
													>
														{localLang.add}
													</PackText>
												</ActionDiv>
											) : (
												<ActionDiv>
													<CustomIconButton
														aria-label="remove"
														onClick={() => handleDecrement(index)}
													>
														<Remove
															color="primary"
															sx={{
																fontSize: "0.875rem",
															}}
														/>
													</CustomIconButton>

													<PackText variant="h6">{quantity[index]}</PackText>

													<CustomIconButton
														aria-label="add"
														onClick={() => handleIncrement(index)}
													>
														<Add
															color="primary"
															sx={{
																fontSize: "0.875rem",
															}}
														/>
													</CustomIconButton>
												</ActionDiv>
											)}
										</CardSize>
									))}
								</CardContainerDiv>

								{/* Tips */}

								<TipsDiv>
									<IconTextDiv>
										<EmojiObjectsOutlined style={{ color: "#2196F3", fontSize: "1.125rem" }} />
										<PackText
											variant="body2"
											sx={{ color: "#212121" }}
										>
											{localLang.packstext}
										</PackText>
									</IconTextDiv>
									<TipText variant="body2">• {localLang.tipstext1}</TipText>
									<TipText variant="body2">• {localLang.tipstext2}</TipText>
								</TipsDiv>
							</TabPanel>

							{/* Buy Individual Points */}

							<TabPanel
								value={bulktabvalue}
								index={1}
							>
								<BuyIndividualDiv>
									<TextfieldDiv>
										{isBeegruPointsActive ? (
											<>
												<TextField
													id="input-with-icon-textfield"
													label={localLang.labelText1}
													value={beegruPoints === 0 ? "" : String(beegruPoints).replace(/[^.0-9]/g, "")}
													onChange={handleBeegruPointsChange}
													size="small"
													slotProps={{
														input: {
															startAdornment: (
																<InputAdornment position="start">
																	<img
																		src="/images/icons/beegru-points/beegru-points-logo.svg"
																		alt="beegru-points"
																		style={{
																			height: 14,
																			width: 14,
																		}}
																	/>
																	{/* <SvgIcon
																		component={BeegruPoints}
																		inheritViewBox={false}
																		viewBox="0 0 18 18"
																		width={24}
																		height={24}
																		sx={{
																			width: "1rem",
																			height: "1rem",
																		}}
																	/> */}
																</InputAdornment>
															),
														},
													}}
													inputProps={{
														inputMode: "numeric",
														pattern: "[0-9]*",
													}}
													variant="outlined"
												/>

												<IconButton
													onClick={handleSwap}
													aria-label="swap"
												>
													<SwapVertOutlined />
												</IconButton>

												<TextField
													id="input-with-icon-textfield"
													label={localLang.labelText2}
													value={indianRupees === 0 ? "" : String(indianRupees).replace(/[^.0-9]/g, "")}
													onChange={handleIndianRupeesChange}
													size="small"
													slotProps={{
														input: {
															startAdornment: (
																<InputAdornment position="start">
																	<CurrencyRupee
																		sx={{
																			fontSize: "1rem",
																		}}
																	/>
																</InputAdornment>
															),
														},
													}}
													inputProps={{
														inputMode: "numeric",
														pattern: "[0-9]*",
													}}
													variant="outlined"
												/>
											</>
										) : (
											<>
												<TextField
													id="input-with-icon-textfield"
													label={localLang.labelText2}
													value={indianRupees === 0 ? "" : String(indianRupees).replace(/[^.0-9]/g, "")}
													onChange={handleIndianRupeesChange}
													size="small"
													slotProps={{
														input: {
															startAdornment: (
																<InputAdornment position="start">
																	<CurrencyRupee
																		sx={{
																			fontSize: "1rem",
																		}}
																	/>
																</InputAdornment>
															),
														},
													}}
													inputProps={{
														inputMode: "numeric",
														pattern: "[0-9]*",
													}}
													variant="outlined"
												/>

												<IconButton
													onClick={handleSwap}
													aria-label="swap"
												>
													<SwapVertOutlined />
												</IconButton>

												<TextField
													id="input-with-icon-textfield"
													label={localLang.labelText1}
													value={beegruPoints === 0 ? "" : String(beegruPoints).replace(/[^.0-9]/g, "")}
													onChange={handleBeegruPointsChange}
													size="small"
													slotProps={{
														input: {
															startAdornment: (
																<InputAdornment position="start">
																	<img
																		src="/images/icons/beegru-points/beegru-points-logo.svg"
																		alt="beegru-points"
																		style={{
																			height: 14,
																			width: 14,
																		}}
																	/>
																	{/* <SvgIcon
																		component={BeegruPoints}
																		inheritViewBox={false}
																		viewBox="0 0 18 18"
																		width={24}
																		height={24}
																		sx={{
																			width: "1rem",
																			height: "1rem",
																		}}
																	/> */}
																</InputAdornment>
															),
														},
													}}
													inputProps={{
														inputMode: "numeric",
														pattern: "[0-9]*",
													}}
													variant="outlined"
												/>
											</>
										)}
									</TextfieldDiv>

									{/* Conversion Rate */}

									<div
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-between",
											alignItems: "center",
											gap: "0.5rem",
											margin: "1rem 0rem 0rem 0rem",
										}}
									>
										<PointDiv>
											<img
												src="/images/icons/beegru-points/beegru-points-logo.svg"
												alt="beegru-points"
												style={{
													height: 14,
													width: 14,
													margin: "0 0.25rem 0 0",
												}}
											/>
											{/* <SvgIcon
															component={BeegruPoints}
															inheritViewBox={false}
															viewBox="0 0 24 24"
															width={24}
															height={24}
															sx={{
																width: "1rem",
																height: "1rem",
															}}
														/> */}
											<ConversionRate variant="h6">1 = ₹{conversion_rate}</ConversionRate>
										</PointDiv>
									</div>
								</BuyIndividualDiv>

								{/* Tips */}

								<TipsDiv>
									<IconTextDiv>
										<EmojiObjectsOutlined style={{ color: "#2196F3", fontSize: "1.125rem" }} />
										<PackText
											variant="body2"
											sx={{ color: "#212121" }}
										>
											{localLang.packstext}
										</PackText>
									</IconTextDiv>
									<TipText variant="body2">• {localLang.tipstext1}</TipText>
									<TipText variant="body2">• {localLang.tipstext2}</TipText>
								</TipsDiv>
							</TabPanel>
						</CustomDialogContent>

						{/* Bill Summary */}

						{bulktabvalue === 1 ? (
							/* Points Bill Summary */

							<Collapse in={drawerOpen}>
								<Container>
									{/* To Pay */}

									<TopayTextContainer>
										<PaymentsOutlined style={{ color: "#1BA672" }} />
										<div>
											<PriceText
												variant="body2"
												sx={{
													color: "#212121",
												}}
											>
												{localLang.swipeableToPayPointsText}
											</PriceText>
											<DiscountText
												variant="body2"
												sx={{
													color: "#666666",
												}}
											>
												{localLang.inclTaxText}
											</DiscountText>
										</div>
									</TopayTextContainer>

									<Divider sx={{ margin: "0.5rem 0rem 0.5rem 0rem" }} />

									{/* Beegru Points */}

									<InvoiceDiv>
										<InvoiceText variant="body2">{localLang.beegruPoints}</InvoiceText>
										<InvoiceText variant="body2">
											<InvoiceText variant="body2">{beegruPoints + " x ₹60"}</InvoiceText>
										</InvoiceText>
									</InvoiceDiv>

									<Divider sx={{ margin: "0.5rem 0rem 0.5rem 0rem" }} />

									{/* Grand Total */}

									<InvoiceDiv>
										<InvoiceText
											variant="body2"
											sx={{ fontWeight: 500 }}
										>
											{localLang.grandTotal}
										</InvoiceText>
										<InvoiceText
											variant="body2"
											sx={{ fontWeight: 500 }}
										>
											₹{NumberToCurrencyPipe(Number(indianRupees).toFixed(2))}
										</InvoiceText>
									</InvoiceDiv>
								</Container>
							</Collapse>
						) : (
							/* Packs Bill Summary */

							<Collapse in={drawerOpenBulk}>
								<Container>
									{/* To Pay */}

									<TopayTextContainer>
										<PaymentsOutlined style={{ color: "#1BA672" }} />
										<div>
											<PriceText
												variant="body2"
												sx={{
													color: "#212121",
												}}
											>
												{localLang.swipeableToPayPacksText}
											</PriceText>
											<DiscountText
												variant="body2"
												sx={{
													color: "#666666",
												}}
											>
												{localLang.inclTaxText}
											</DiscountText>
										</div>
									</TopayTextContainer>

									<Divider sx={{ margin: "0.5rem 0rem 0.5rem 0rem" }} />

									{/* Packs Summary */}

									{bottom_buy_subscription && bottom_buy_subscription.packof10.quantity > 0 ? (
										<InvoiceDiv>
											<InvoiceText variant="body2">{localLang.pack_of_10}</InvoiceText>
											<InvoiceText variant="body2">{bottom_buy_subscription.packof10.quantity} x ₹600</InvoiceText>
										</InvoiceDiv>
									) : null}

									{bottom_buy_subscription && bottom_buy_subscription.packof100.quantity > 0 ? (
										<InvoiceDiv>
											<InvoiceText variant="body2">{localLang.pack_of_100}</InvoiceText>
											<InvoiceText variant="body2">{bottom_buy_subscription.packof100.quantity} x ₹6,000</InvoiceText>
										</InvoiceDiv>
									) : null}

									{bottom_buy_subscription && bottom_buy_subscription.packof500.quantity > 0 ? (
										<InvoiceDiv>
											<InvoiceText variant="body2">{localLang.pack_of_500}</InvoiceText>
											<InvoiceText variant="body2">{bottom_buy_subscription.packof500.quantity} x ₹30,000</InvoiceText>
										</InvoiceDiv>
									) : null}

									{bottom_buy_subscription && bottom_buy_subscription.packof1000.quantity > 0 ? (
										<InvoiceDiv>
											<InvoiceText variant="body2">{localLang.pack_of_1000}</InvoiceText>
											<InvoiceText variant="body2">{bottom_buy_subscription.packof1000.quantity} x ₹60,000</InvoiceText>
										</InvoiceDiv>
									) : null}

									<Divider sx={{ margin: "0.5rem 0rem 0.5rem 0rem" }} />

									{/* Subtotal & Discount */}

									<InvoiceDiv>
										<InvoiceText variant="body2">{localLang.subTotalText}</InvoiceText>
										<InvoiceText variant="body2">₹{NumberToCurrencyPipe(totalPrice.toFixed(2))}</InvoiceText>
									</InvoiceDiv>

									<InvoiceDiv>
										<InvoiceText variant="body2">{localLang.discount}</InvoiceText>
										<InvoiceText
											variant="body2"
											sx={{
												color: theme.palette.success.main,
											}}
										>
											- ₹{NumberToCurrencyPipe((totalPrice - subTotal).toFixed(2))}
										</InvoiceText>
									</InvoiceDiv>

									{/* <InvoiceDiv>
									<InvoiceText variant="body2">{localLang.subTotalText}</InvoiceText>
									<InvoiceText variant="body2">
										₹{NumberToCurrencyPipe(bottom_buy_subscription.subtotal.toFixed(2))}
									</InvoiceText>
								</InvoiceDiv> */}

									{/* <InvoiceDiv>
									<InvoiceText variant="body2">{localLang.gstText}</InvoiceText>
									<InvoiceText variant="body2">
										₹{NumberToCurrencyPipe(bottom_buy_subscription.gst.toFixed(2))}
									</InvoiceText>
								</InvoiceDiv> */}

									<Divider sx={{ margin: "0.5rem 0rem 0.5rem 0rem" }} />

									{/* Grand Total */}

									<InvoiceDiv>
										<InvoiceText
											variant="body2"
											sx={{ fontWeight: 500 }}
										>
											{localLang.grandTotal}
										</InvoiceText>
										<InvoiceText
											variant="body2"
											sx={{ fontWeight: 500 }}
										>
											₹{NumberToCurrencyPipe(subTotal.toFixed(2))}
										</InvoiceText>
									</InvoiceDiv>
								</Container>
							</Collapse>
						)}

						{/* Bottom Bar */}

						<CustomDialogActions>
							{bulktabvalue === 1 ? (
								/* Buy Points */

								<React.Fragment>
									{/* Total */}

									<TextDiv>
										<TopayDiv>
											<DetailText variant="body2">{localLang.viewDetailsText}</DetailText>

											<SubTotalText
												variant="body2"
												sx={{
													fontWeight: 500,
												}}
											>
												₹{NumberToCurrencyPipe(Number(indianRupees).toFixed(2))}
											</SubTotalText>

											{Number(indianRupees) > 0 ? (
												drawerOpen ? (
													<ExpandMoreOutlined
														onClick={handleDrawerClick}
														style={{ color: theme.palette.primary.main }}
													/>
												) : (
													<ExpandLessOutlined
														onClick={handleDrawerClick}
														style={{ color: "#666666" }}
													/>
												)
											) : null}
										</TopayDiv>
									</TextDiv>

									{/* Pay Button */}

									{!paymentLoading ? (
										<BeegruButton
											flavor="success"
											variant="contained"
											disabled={amount === 0 || paymentLoading}
											name="proceed"
											type="button"
											onClick={processPayment}
										>
											{localLang.payNowText}
										</BeegruButton>
									) : (
										<CircularProgress />
									)}
								</React.Fragment>
							) : (
								/* Buy Pack */

								<React.Fragment>
									{/* Total */}

									<TextDiv>
										<TopayDiv>
											<DetailText variant="body2">{localLang.viewDetailsText}</DetailText>

											<SubTotalText
												variant="body2"
												sx={{
													fontWeight: 500,
												}}
											>
												₹{NumberToCurrencyPipe(grandTotal.toFixed(2))}
											</SubTotalText>

											{subTotal > 0 ? (
												drawerOpenBulk ? (
													<ExpandMoreOutlined
														onClick={handleDrawerBulkClick}
														style={{ color: theme.palette.primary.main }}
													/>
												) : (
													<ExpandLessOutlined
														onClick={handleDrawerBulkClick}
														style={{ color: "#666666" }}
													/>
												)
											) : null}
										</TopayDiv>

										{totalPrice - subTotal > 0 ? (
											<SubTotalText
												variant="body2"
												sx={{
													color: theme.palette.success.main,
													fontWeight: 500,
												}}
											>
												{"₹" + NumberToCurrencyPipe((totalPrice - subTotal).toFixed(2)) + localLang.saved}
											</SubTotalText>
										) : null}
									</TextDiv>

									{/* Pay Button */}

									{!paymentLoading ? (
										<BeegruButton
											flavor="success"
											variant="contained"
											disabled={quantity.reduce((a, b) => a + b, 0) === 0 || paymentLoading}
											name="proceed"
											type="button"
											onClick={processPayment}
										>
											{localLang.payNowText}
										</BeegruButton>
									) : (
										<CircularProgress />
									)}
								</React.Fragment>
							)}
						</CustomDialogActions>
					</>
				)}
			</CustomDialog>
		</React.Fragment>
	);
};

export default BuyBeegruPointsModal;
