/* Redux Imports */

import { BHK, PricePerUnit } from "../redux-magic/sub-interfaces/sub-interfaces";

const prepareConfigString = (
	transaction_type: string,
	property_type: Array<string>,
	bhk: BHK,
	price_per_unit: PricePerUnit,
	floors: BHK,
	no_of_seats: BHK,
	dont_show_rupee_icon?: boolean,
) => {
	if (property_type !== undefined) {
		if (property_type.includes("Shop")) {
			return "Commercial";
		}
		if (property_type.includes("Studio Apartment")) {
			/* Studio Apartment */

			return "Studio";
		} else if (property_type.includes("Co-living/PG")) {
			/* Co-living */

			if (transaction_type === "rent") {
				return "Shared";
			} else {
				return bhk.max + " Bedrooms";
			}
		} else if (property_type.includes("Building")) {
			/* Building */

			return floors.max + " Floors";
		} else if (
			property_type.includes("Plot") ||
			property_type.includes("Farm Plot") ||
			property_type.includes("Land (Residential)") ||
			property_type.includes("Land (Commercial)") ||
			property_type.includes("Land (Agricultural)") ||
			property_type.includes("Land (Industrial)") ||
			property_type.includes("Office Space") ||
			property_type.includes("Factory") ||
			property_type.includes("Mining") ||
			property_type.includes("Warehouse")
		) {
			/* Plots, Lands, Office space, Factory, Mining, Warehouse */

			if (transaction_type === "rent") {
				return (
					(price_per_unit.max.ft !== null ? (dont_show_rupee_icon ? "" : "₹ ") + price_per_unit.max.ft.toFixed(0) : 0) +
					" / sqft"
				);
			} else {
				return (
					(price_per_unit.max.ft !== null ? (dont_show_rupee_icon ? "" : "₹ ") + price_per_unit.max.ft.toFixed(0) : 0) +
					" / sqft"
				);
			}
		} else if (property_type.includes("Co-working")) {
			/* Co-working */

			if (transaction_type === "rent") {
				return no_of_seats.max + " Seats";
			} else {
				return no_of_seats.max + " Seats";
			}
		} else if (
			property_type.includes("Apartment") ||
			property_type.includes("Independent House") ||
			property_type.includes("Villa") ||
			property_type.includes("Row House") ||
			property_type.includes("Builder Floor Apartment") ||
			property_type.includes("Penthouse") ||
			property_type.includes("Farm House")
		) {
			/* Apartments, Independent House, Villa, Row House, Builder Floor Apartment, Penthouse, Farm House */

			return bhk.min === bhk.max ? bhk.max + " BHK" : bhk.min + " - " + bhk.max + " BHK";
		}
	}
};

export default prepareConfigString;
