/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import StateInterface from "../../../redux-magic/state-interface";
import { PublishServiceThunk } from "../../../redux-magic/store";

/* Component Imports */

import {
	// IconButton,
	Alert,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { ObjectId } from "mongodb";
import {
	BusinessProfileInterface,
	ServiceInterface,
	UserInterface,
} from "../../../redux-magic/sub-interfaces/sub-interfaces";
import ServiceCardPublishPreview from "../../cards/ServiceCardPublishPreview/ServiceCardPublishPreview";
import BeegruButton from "../../common-components/buttons/BeegruButton";
/* Icon Imports */

// import CloseIcon from "@mui/icons-material/Close";

/* Styled Components */

const CustomDialog = styled(Dialog)(({ theme }) => ({
	padding: "1rem 1rem 1rem 1rem",
	[theme.breakpoints.down("sm")]: {
		top: "5rem",
		bottom: "5rem",
	},
}));

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
	padding: "0.5rem 1rem 0.5rem 1rem",
	fontSize: "1.25rem",
	fontWeight: 400,
	background: theme.palette.background.paper,
}));

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	padding: "1rem 1rem 1rem 1rem",
	background: theme.palette.background.paper,
	[theme.breakpoints.down("sm")]: { padding: "0rem 0rem 0rem 0rem" },
}));

const CustomDialogActions = styled(DialogActions)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-end",
	alignItems: "center",
	width: "100%",
	gap: "0.5rem",
	padding: "0rem 1rem 1rem 0rem",
	background: theme.palette.background.paper,
}));

const Snack = styled(Snackbar)(({ theme }) => ({
	// [theme.breakpoints.down("md")]: {
	// 	bottom: "5rem",
	// 	borderRadius: "0.5rem",
	// },
	[theme.breakpoints.up("sm")]: {
		marginTop: "2.5rem",
	},
}));

interface LocalServiceInterface extends Omit<ServiceInterface, "created_by"> {
	created_by: {
		is_business_profile: boolean;
		business_profile_id: string | ObjectId | BusinessProfileInterface;
		user_id: string | ObjectId | UserInterface;
	};
}

const PublishButtonModal = ({
	serviceData,
	open,
	handleClose,
	dispatch,
	profile_context,
	isEditor,
	session,
}: {
	serviceData: LocalServiceInterface;
	open: boolean;
	handleClose: any;
	dispatch: Function;
	profile_context: StateInterface["profile_context"];
	isEditor: boolean;
	session: any;
}) => {
	const theme = useTheme();

	const profile_link = serviceData
		? serviceData.created_by.is_business_profile
			? process.env.PRODUCTION_URL +
				`${(serviceData.created_by.business_profile_id as BusinessProfileInterface).business_profile_type}s/${(serviceData.created_by.business_profile_id as BusinessProfileInterface).slug}`
			: process.env.PRODUCTION_URL + `users/${(serviceData.created_by.user_id as UserInterface)._id}`
		: "";

	const [openPublishSnack, setOpenPublishSnack] = React.useState<boolean>(false);

	const [openUnpublishSnack, setOpenUnpublishSnack] = React.useState<boolean>(false);

	const [load, setLoad] = React.useState<boolean>(false);

	const [serviceCardImage, setServiceCardImage] = React.useState(
		serviceData.images.map((image) => ({
			...image,
			url: typeof image.url === "object" && "regular" in image.url ? image.url.regular : image.url,
		})),
	);

	React.useEffect(() => {
		setServiceCardImage(
			serviceData.images.map((image) => ({
				...image,
				url: typeof image.url === "object" && "regular" in image.url ? image.url.regular : image.url,
			})),
		);
	}, [serviceData]);

	const handleCloseSnack = () => {
		setOpenPublishSnack(false);
		setOpenUnpublishSnack(false);
	};

	const Publish = async () => {
		setLoad(true);
		await dispatch(
			PublishServiceThunk({
				sessionId: session ? (session.session_id ? session.session_id : "") : "",
				phoneNumber: session ? session.user.dbUser.phone : "",
				countryCode: session ? session.user.dbUser.country_code : "",
				service_id: serviceData ? serviceData._id : "",
				status: status === "unpublish" ? "untouched" : "publish_requested",
				creator_is_business_profile: isEditor
					? profile_context.is_business_profile
					: serviceData.created_by.is_business_profile,
				creator_user_id: isEditor ? profile_context.user_id : (serviceData.created_by.user_id as UserInterface)._id,
				creator_business_profile_id: isEditor
					? profile_context.business_profile_id
					: (serviceData.created_by.business_profile_id as BusinessProfileInterface)._id,
			}),
		);
		handleClose();
		if (serviceData.published) {
			setOpenUnpublishSnack(true);
		} else {
			setOpenPublishSnack(true);
		}
		setLoad(false);
		setTimeout(() => {
			window.open(profile_link, "_self");
		}, 1000);
	};

	const status = serviceData && serviceData.status === "published" ? "unpublish" : "publish";

	return (
		<React.Fragment>
			<Snack
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={openUnpublishSnack}
				autoHideDuration={3000}
				onClose={handleCloseSnack}
			>
				<Alert
					onClose={handleCloseSnack}
					severity="success"
					sx={{ width: "100%", borderRadius: "0.75rem" }}
					variant="filled"
				>
					Servcie has been unpublished!
				</Alert>
			</Snack>

			<Snack
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={openPublishSnack}
				autoHideDuration={3000}
				onClose={handleCloseSnack}
			>
				<Alert
					onClose={handleCloseSnack}
					severity="success"
					sx={{ width: "100%", borderRadius: "0.75rem" }}
					variant="filled"
				>
					We will notify you once the servcie is published
				</Alert>
			</Snack>

			<CustomDialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth={true}
				fullScreen={useMediaQuery(theme.breakpoints.down("sm")) ? true : false}
				disableScrollLock={true}
			>
				<CustomDialogTitle>
					{!serviceData.published ? "Service card preview" : "Are you sure you want to unpublish this service?"}
				</CustomDialogTitle>

				{!serviceData.published ? (
					<CustomDialogContent>
						<ServiceCardPublishPreview
							loading={false}
							title={serviceData.title}
							images={serviceCardImage[0] ? (serviceCardImage[0].url as string) : ""}
							location={serviceData.location ? serviceData.location.title : ""}
							price={serviceData.price}
							service_provider_name={serviceData.service_provider ? serviceData.service_provider.name : ""}
							serviceSubtype={serviceData.service_subtype}
							show_contact_details={false}
							source={"search"}
						/>
					</CustomDialogContent>
				) : null}

				<CustomDialogActions
					sx={{
						flexDirection: !serviceData.published ? "row" : "row-reverse",
						justifyContent: !serviceData.published ? "flex-end" : "flex-start",
					}}
				>
					<BeegruButton
						flavor="primary"
						variant={!serviceData.published ? "text" : "outlined"}
						onClick={handleClose}
						name="cancel"
						type="button"
					>
						{!serviceData.published ? "Cancel" : "No"}
					</BeegruButton>

					{load ? (
						<CircularProgress
							sx={{
								marginTop: "1rem",
							}}
						/>
					) : (
						<BeegruButton
							flavor="primary"
							variant={!serviceData.published ? "contained" : "text"}
							onClick={Publish}
							name="publish"
							type="button"
						>
							{!serviceData.published ? "Publish" : "Yes"}
						</BeegruButton>
					)}
				</CustomDialogActions>
			</CustomDialog>
		</React.Fragment>
	);
};

export default PublishButtonModal;
